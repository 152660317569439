import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import Select from 'react-select';
import { ShippingCountryStorage } from '../../../../storage/shippingCountryStorage';
import './ShippingCountryModal.css';

export function ShippingCountryModal({ setShowModal, setShipCountry }) {
    const modalWindowRef = useRef();
    const shipCountryStorage = new ShippingCountryStorage();
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(shipCountryStorage.getCountry());

    useEffect(async () => {
        const countries = await fetch(ApiEndpoints.ShippingCountries);
        const data = await countries.json();
        setCountries(data);
    }, []);

    const handleCountryChange = async (selectedOption) => {
        setSelectedCountry({ id: selectedOption.value, name: selectedOption.label });
    };

    const saveCountry = () => {
        if (!selectedCountry) return;
        shipCountryStorage.setCountry(selectedCountry.id, selectedCountry.name);
        setShipCountry(selectedCountry);
        modalWindowRef.current.closeWindow();
    }

    return (
        <div className="shipping-country-modal">
            <ModalWindow
                ref={modalWindowRef}
                title="Select shipping country"
                onClose={() => { setShowModal(false) }}
            >
                <Select
                    onChange={handleCountryChange}
                    defaultValue={{ value: selectedCountry?.id, label: selectedCountry?.name }}
                    options={countries.map(x => ({ value: x.id, label: x.name }))}
                />
                <p className="no-country-hint">If your country is not listed, feel free to contact us – we’ll be happy to explore shipping options together!</p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Close window</button>
                  <button type="button" className="btn btn-primary" onClick={() => saveCountry()}>Select</button>
                </div>
            </ModalWindow>
        </div>
    );
}
import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import { Fireworks } from './fireworks/Fireworks';
import './ShopCartAddedModal.css';

export function ShopCartAddedModal({ item, setItem }) {
    const modalWindowRef = useRef();
    const [loaded, setLoaded] = useState(0);
    useEffect(() => {
        setLoaded(e => e+1);
    }, []);

    return (
        <div className="shop-cart-added-modal">
            <div className="d-none">{loaded}</div>
            <ModalWindow
                ref={modalWindowRef}
                title="Item added to cart"
                onClose={() => { setItem(null) }}
            >
                <p className="item-added-text">You have successfully added the item to your cart</p>
                <div className="d-flex justify-content-between align-items-center">
                    <img src={item.image} className="added-img"/>
                    <p className="item-title">{item.title}</p>
                </div>
                <Fireworks />
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Continue shopping</button>
                  <a href="/cart" type="button" className="btn btn-primary">Go to cart</a>
                </div>
            </ModalWindow>
        </div>
    );
}
import React, { useRef } from 'react';
import { ModalWindow } from '../../modal/ModalWindow';
import './PopularityUpdateModal.css';

export function PopularityUpdateModal() {
    const modalWindowRef = useRef();

    return (
        <div className="popularity-info">
            <ModalWindow
                ref={modalWindowRef}
                title="It’s time for a popularity values update!"
                onClose={() => window.location.reload()}
            >
                <p>
                    We’re currently updating product popularity data to keep everything accurate.
                </p>
                <p>
                    Please refresh the page to ensure the information is up to date and synchronized with the latest rankings.
                </p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary w-100" onClick={() => window.location.reload()}>Refresh page</button>
                </div>
            </ModalWindow>
        </div>
    );
}
import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import './ShopCartExceedsLimit.css';
import { MaxItemsInCart } from '../ShopCart';

export function ShopCartExceedsLimit({ setShopLimitReached }) {
    const modalWindowRef = useRef();

    return (
        <div className="shop-cart-exceeds-quantity">
            <ModalWindow
                ref={modalWindowRef}
                title="Cart Limit Reached"
                onClose={() => { setShopLimitReached(false); }}
            >
                <p>You have reached the maximum allowed quantity for your cart</p>
                <p>You cannot add more than {MaxItemsInCart} items in your cart</p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Continue shopping</button>
                  <a href="/cart" type="button" className="btn btn-primary">Go to cart</a>
                </div>
            </ModalWindow>
        </div>
    );
}
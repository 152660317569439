import React from 'react';
import { CautionSvgIcon } from '../../../../assets/shop/CautionSvgIcon';

export const OrderStatusBadge = ({ status }) => {
   const getStatusClassName = (status) => {
        switch (status) {
            case 'New': return 'badge-warning';
            case 'Cancelled': return 'badge-danger';
            case 'Payment received' : return 'badge-success';
            case 'Shipped' : return 'badge-success';
            case 'Delivered' : return 'badge-success';
            default: return 'badge-secondary';
        }
    }

    return (
        <span class={`badge order-badge ${getStatusClassName(status)}`}>
            {status === 'New' && <CautionSvgIcon />}{status}
        </span>
    );
}
import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';

export function UserResetAccessFailedCountModal({userId, setUserId, fetchUsers, setPage}) {
    const [submitting, setSubmitting] = useState(false);
    const modalWindowRef = useRef();

    const confirmSubmit = async (e) => {
        if (submitting) return;
        setSubmitting(true);

        await fetch(`${ApiEndpoints.ResetAccessFailedCount}/${userId}`, { method: "GET" });
        setPage(0);
        await fetchUsers(0);
        modalWindowRef.current.closeWindow();
    }

    return (
        <ModalWindow
            ref={modalWindowRef}
            title="Confirm reset access failed count"
            onClose={() => setUserId(null)}
        >
            <p>Are you sure you want to reset access failed count for this user?</p>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={confirmSubmit} disabled={submitting}>Confirm reset</button>
            </div>
        </ModalWindow>
    );
}
import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/home/Home';
import { Loading } from './components/loading/Loading';
import { About } from './components/about/About';
import { AccessDenied } from './components/errors/accessDenied/AccessDenied';
import { NotFound } from './components/errors/notFound/NotFound';
import { MediaGallery } from './components/gallery/MediaGallery';
import { Shop } from './components/shop/Shop';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { ShopItemCategoriesList } from './components/shop/management/shopItemCategories/ShopItemCategoriesList';
import { ShopItemsList } from './components/shop/management/shopItems/ShopItemsList';
import { ShopItemDetails } from './components/shop/item/details/ShopItemDetails';
import { ShopDiscountList } from './components/shop/management/discounts/ShopDiscountList';
import { ShopDeliveriesList } from './components/shop/management/deliveries/ShopDeliveriesList';
import { ShopCart } from './components/shop/cart/ShopCart';
import { ShopManagementDashboard } from './components/shop/management/ShopManagementDashboard';
import { UsersList } from './components/shop/management/users/UsersList';
import { MyProfile } from './components/shop/myProfile/MyProfile';
import { SuccessPayment } from './components/shop/payment/successPayment/SuccessPayment';
import { TranslateWidget } from './components/TranslateWidget';
import { OrderDetails } from './components/shop/orderDetails/OrderDetails';
import { ShopOrdersList } from './components/shop/management/shopOrders/ShopOrdersList';
import { ShopOrderManage } from './components/shop/management/shopOrders/orderManage/ShopOrderManage';
import { ShopFaq } from './components/shop/faq/ShopFaq';
import 'animate.css';

import './appCommon.css'

export default function App() {
  const displayName = App.name;

    return (
      <Fragment>
          <Loading />
          <TranslateWidget />
          <Layout>
             <Switch>
                { /* General info */ }
                 <Route exact path='/' component={Home} />
                 <Route exact path='/illustration' component={MediaGallery} />
                 <Route exact path='/about' component={About} />

                { /* Shop */ }
                 <Route exact path='/shop' component={Shop} />
                 <Route exact path='/faq' component={ShopFaq} />
                 <Route exact path='/shop/:itemId' render={({ match }) =>
                    <ShopItemDetails itemId={match.params.itemId} />}
                 />
                 <Route exact path='/cart' component={ShopCart} />
                 <Route exact path='/profile' component={MyProfile} />
                 <Route exact path='/order/:orderId' render={({ match }) =>
                     <OrderDetails orderId={match.params.orderId} />}
                  />
                 <Route exact path='/successPayment' component={SuccessPayment} />

                { /* Shop management */ }
                 <Route exact path='/shop/management/main' component={ShopManagementDashboard} />
                 <Route exact path='/shop/management/categories' component={ShopItemCategoriesList} />
                 <Route exact path='/shop/management/items' component={ShopItemsList} />
                 <Route exact path='/shop/management/discounts' component={ShopDiscountList} />
                 <Route exact path='/shop/management/deliveries' component={ShopDeliveriesList} />
                 <Route exact path='/shop/management/users' component={UsersList} />
                 <Route exact path='/shop/management/orders' component={ShopOrdersList} />
                  <Route exact path='/order/manage/:orderId' render={({ match }) =>
                      <ShopOrderManage orderId={match.params.orderId} />}
                  />

                { /* Errors */ }
                 <Route exact path='/403' component={AccessDenied} />
                 <Route exact path='/404' component={NotFound} />

                 { /* Authorization */ }
                 <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

                 { /* Fallback */ }
                 <Route path="*" render={() => <Redirect to="/404" />} />
             </Switch>
          </Layout>
      </Fragment>
    );
}

import React, { Fragment, useState, useEffect } from 'react';
import './shopDiscountList.css';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ShopDiscountCreateEditModal } from './ShopDiscountCreateEditModal';
import { ShopDiscountDeleteModal } from './ShopDiscountDeleteModal';

export function ShopDiscountList() {
    const [discounts, setDiscounts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentDiscount, setCurrentDiscount] = useState({ name: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDiscountId, setDeleteDiscountId] = useState(null);

    useEffect(() => {
        fetchDiscounts();
    }, []);

    const fetchDiscounts = async () => {
        const response = await fetch(ApiEndpoints.ManageShopDiscounts);
        const data = await response.json();
        setDiscounts(data);
    };

    const handleCreate = () => {
        setCurrentDiscount({ name: '' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleEdit = (discount) => {
        setCurrentDiscount(discount);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        setDeleteDiscountId(id);
    };

    return (
        <Fragment>
            <NavMenuShop />
            <div className="container discount-manager">
                <button className="btn btn-primary mb-3" onClick={handleCreate}>Create Discount</button>
                 <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{ minWidth: 160 }}>Start - End</th>
                            <th width="50">%</th>
                            <th style={{ minWidth: 120 }}>Used / Limit</th>
                            <th style={{ minWidth: 160 }}>Promo Code</th>
                            <th width="100">Stackable</th>
                            <th width="100">Active</th>
                            <th style={{ minWidth: 180 }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {discounts.map((discount, index) => (
                             <tr>
                                <td data-label="Name">{discount.name}</td>
                                <td data-label="Start - End">
                                    {discount.startDate && new Date(discount.startDate).toLocaleDateString()}
                                    -
                                    {discount.endDate && new Date(discount.endDate).toLocaleDateString()}</td>
                                <td data-label="%">{discount.percent}</td>
                                <td data-label="Used / Limit">{discount.usageCount} / {discount.usageLimit}</td>
                                <td data-label="Promo Code">{discount.promoCode}</td>
                                <td data-label="Stackable">{discount.stackable ? "✅" : "❌"}</td>
                                <td data-label="Active">{discount.active ? "✅" : "❌"}</td>
                                <td data-label="Actions">
                                    <button className="btn btn-warning mr-2" onClick={() => handleEdit(discount)}>Edit</button>
                                    <button className="btn btn-danger" onClick={() => handleDelete(discount.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {showModal && (
                    <ShopDiscountCreateEditModal
                        currentDiscount={currentDiscount}
                        setCurrentDiscount={setCurrentDiscount}
                        setShowModal={setShowModal}
                        fetchDiscounts={fetchDiscounts}
                        isEditing={isEditing}
                    />
                )}
                {deleteDiscountId && (
                    <ShopDiscountDeleteModal
                        deleteDiscountId={deleteDiscountId}
                        setDeleteDiscountId={setDeleteDiscountId}
                        fetchDiscounts={fetchDiscounts}
                    />
                )}
            </div>
        </Fragment>
    );
}

import React, { useState, useEffect } from 'react';
import { TreeView } from './TreeView';
import './shopFilters.css';
import { buildTreeData } from '../../../functions/categoryTrees';

export const ShopFilters = ({ filters, currentCategory, setCurrentCategory, initialCategory, fetchItems, updateUrl, setPage, fetchingData }) => {

  const handleNodeClick = async (node) => {
    setCurrentCategory(node);
    updateUrl(node);
    setPage(0);
    await fetchItems(node, null, 0);
  };

  const categories = filters && filters.categories
      ? buildTreeData(filters.categories)
      : [];

  return (
    <div className="shop-filters">
      {categories && (
          <TreeView
            data={categories}
            onNodeClick={handleNodeClick}
            initialCategory={initialCategory}
            disabled={fetchingData}
          />
      )}
     <button className="p-0 mt-2 btn btn-link reset-shop-category-btn" disabled={fetchingData} onClick={() => handleNodeClick(false)}>Show all items</button>
    </div>
  );
};

export function calculateOptimalShippingMethod(shippingMethods, shopItems) {
    if (
        !shippingMethods ||
        !shopItems ||
        shippingMethods.length === 0 ||
        shopItems.length === 0
    ) return {};

    const n = shopItems.length;
    const fullMask = (1 << n) - 1;
    const dp = Array(1 << n).fill(Infinity);
    dp[0] = 0;

    const shippingMethodsMap = shippingMethods.reduce((acc, method) => {
        acc[method.id] = method;
        return acc;
    }, {});
    
    for (let item of shopItems) {
        let validDeliveryFound = false;
        if (!item.deliveriesIds || item.deliveriesIds.length === 0) {
           return {};
        }

        for (let deliveryId of item.deliveriesIds) {
            if (shippingMethodsMap[deliveryId]) {
                validDeliveryFound = true;
                break;
            }
        }
        if (!validDeliveryFound) {
            return {};
        }
    }

    for (let method of shippingMethods) {
        const { id, price } = method;

        let deliveryMask = 0;
        for (let i = 0; i < n; i++) {
            if (shopItems[i].deliveriesIds.includes(id)) {
                deliveryMask |= (1 << i); 
            }
        }

        for (let oldMask = 0; oldMask <= fullMask; oldMask++) {
            const newMask = oldMask | deliveryMask; 
            dp[newMask] = Math.min(dp[newMask], dp[oldMask] + price);
        }
    }

    const resultPrice = dp[fullMask];

    const optimalShippings = {};
    let currentMask = fullMask;
    while (currentMask > 0) {
        for (let method of shippingMethods) {
            let deliveryMask = 0;
            for (let i = 0; i < n; i++) {
                if (shopItems[i].deliveriesIds.includes(method.id)) {
                    deliveryMask |= (1 << i);
                }
            }
            const prevMask = currentMask & ~deliveryMask;
            if (dp[currentMask] === dp[prevMask] + method.price) {
                for (let i = 0; i < n; i++) {
                    if (deliveryMask & (1 << i)) {
                        optimalShippings[shopItems[i].id] = { deliveryId: method.id, price: method.price };
                    }
                }
                currentMask = prevMask;
                break;
            }
        }
    }

    return optimalShippings;
}

import React, { Component, Fragment } from 'react';
import './home.css'

import homeImg from '../../assets/home/home.PNG';
import homeSmallImg from '../../assets/home/home_small.PNG';

import titleImg from '../../assets/home/title.PNG';
import shopImg from '../../assets/home/shop.PNG';
import illustrationImg from '../../assets/home/illustration.PNG';
import aboutImg from '../../assets/home/about.PNG';
import flowersImg from '../../assets/home/flowers.PNG';

import patreonImg from '../../assets/home/patreon.PNG';
import koFiImg from '../../assets/home/kofi.PNG';
import instagramImg from '../../assets/home/instagram.PNG';
import contactImg from '../../assets/home/contact.PNG';

import titleImgSelected from '../../assets/home/title.PNG';
import shopImgSelected from '../../assets/home/shop.PNG';
import illustrationImgSelected from '../../assets/home/illustration.PNG';
import aboutImgSelected from '../../assets/home/about.PNG';

import patreonImgSelected from '../../assets/home/patreon.PNG';
import koFiImgSelected from '../../assets/home/kofi.PNG';
import instagramImgSelected from '../../assets/home/instagram.PNG';
import contactImgSelected from '../../assets/home/contact.PNG';

export class Home extends Component {
  static displayName = Home.name;
  
buttons = [
  { normal: titleImg, hover: titleImgSelected },
  { normal: shopImg, hover: shopImgSelected },
  { normal: illustrationImg, hover: illustrationImgSelected },
  { normal: aboutImg, hover: aboutImgSelected },

  { normal: patreonImg, hover: patreonImgSelected },
  { normal: koFiImg, hover: koFiImgSelected },
  { normal: instagramImg, hover: instagramImgSelected },
  { normal: contactImg, hover: contactImgSelected },
];
  
 handleMouseOver = (e, hoverImage) => {
    e.currentTarget.src = hoverImage;
  }

  handleMouseOut = (e, normalImage) => {
    e.currentTarget.src = normalImage;
  }

  render () {
    return (
         <Fragment>
             <meta
                name="description"
                content="Anna Shepeta, a neurodivergent artist by the Baltic Sea, creates vibrant illustrations and art with passion. Discover her work, shop, and creative journey!"
            />
            <div className="home-content">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-12 text-center">
                        <a href="/">
                            <img src={homeImg} alt="Home" className="img-fluid header-img" />
                        </a>
                      </div>
                       <div className="col-12 text-center row justify-content-center">
                          <div className="text-center title-container">
                            <img src={titleImg} alt="Anna Shepeta. Artist and Illustrator" className="img-fluid" />
                          </div>
                      </div>
                      <div className="d-flex justify-content-around menu-images">
                          <div className="menu-item menu-item-images">
                            <a href="/shop">
                                <img src={shopImg} alt="Shop" className="img-fluid"/>
                            </a>
                          </div>
                          <div className="menu-item menu-item-images">
                            <a href="/illustration">
                                <img src={illustrationImg} alt="Illustration" className="img-fluid" />
                            </a>
                          </div>
                          <div className="menu-item menu-item-images">
                            <a href="/about">
                                <img src={aboutImg} alt="About" className="img-fluid" />
                            </a>
                          </div>
                      </div>

                      <div className="menu-buttons d-flex justify-content-around">
                          <div className="text-center menu-item menu-item-buttons">
                            <a href="https://patreon.com/AnnaShepeta">
                                 <img src={patreonImg} alt="Patreon" className="img-fluid" />
                            </a>
                          </div>
                          <div className="text-center menu-item menu-item-buttons">
                            <a href="https://ko-fi.com/halbermensch?fbclid=PAAaaDsOXTWJCv6uEHzz_UJUw8U3sj0ltOfbYiUxq35-HEZWd8_xunyBH6QXk">
                                <img src={koFiImg} alt="Ko-Fi" className="img-fluid" />
                            </a>
                          </div>
                          <div className="text-center menu-item menu-item-buttons">
                            <a href="https://www.instagram.com/halber.mensch/">
                                 <img src={instagramImg} alt="Instagram" className="img-fluid" />
                            </a>
                          </div>
                          <div className="text-center menu-item menu-item-buttons">
                            <a href="mailto:contact@annashepeta.art" className="overlay-link contact-link">
                                <img src={contactImg} alt="Contact" className="img-fluid" />
                            </a>
                          </div>
                      </div>
                      <div className="justify-content-center d-flex">
                        <div className="text-center flowers-container">
                            <img src={flowersImg} alt="Flowers" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    </div>
          </div>
         </Fragment>
     );
  }
}

import * as Yup from 'yup';

export class ShopItemCategoryCreateEditValidation {
    constructor() {
        this.validationSchema = Yup.object().shape({
            name: Yup.string()
                .required('Please fill the field: Name')
                .max(100, 'Name cannot be more than 100 characters')
        });
    }

    async validate(category) {
        try {
            await this.validationSchema.validate(category, { abortEarly: false });
            return null;
        } catch (errors) {
            return errors.inner[0].message;
        }
    }
}

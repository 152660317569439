import React, { Fragment, useState, useEffect } from 'react';
import './shop.css'
import { NavMenuShop } from '../navMenu/NavMenuShop';
import { ShopFilters } from './filters/ShopFilters';
import { useLocation } from 'react-router-dom';
import { ApiEndpoints } from '../../api/apiEndpoints';
import { ShopItemPreview } from './item/preview/ShopItemPreview';
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerImg from '../../assets/spinner.gif';
import { ShopCartAddedModal } from './cart/cartAddedModal/ShopCartAddedModal';
import { ShopCartExceedsStockQuantity } from './cart/cartExceedsStockQuantity/ShopCartExceedsStockQuantity';
import { ShopCartExceedsLimit } from './cart/cartExceedsLimit/ShopCartExceedsLimit';
import { PopularityUpdateModal } from './popularityUpdateModal/PopularityUpdateModal';
import schedule from 'node-schedule';

export function Shop() {
    const query = new URLSearchParams(useLocation().search);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [initialCategory, setInitialCategory] = useState(null);
    const [sortBy, setSortBy] = useState('Recommended');
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);
    const [currentCartItem, setCurrentCartItem] = useState(null);
    const [shopExceedsQuantityModal, setShopExceedsQuantityModal] = useState(false);
    const [shopCartLimitReachedModal, setShopCartLimitReachedModal] = useState(false);
    const [popularityUpdateModal, setPopularityUpdateModal] = useState(false);

    useEffect(() => {
        document.title = 'Shop | Anna Shepeta';
        fetchFilters();
      }, []);
  
   const fetchFilters = async () => {
       setFetchingData(true);
        const response = await fetch(ApiEndpoints.ShopFilters);
        const data = await response.json();
        setFilters(data);
        let category = null;

        if (query.get('category')) {
            category = data.categories.find(category => category.id === query.get('category'))
            setCurrentCategory(category);
            setInitialCategory(category);
        }

        let sort = sortBy;
        if (query.get('sortBy')) {
            sort = query.get('sortBy');
            setSortBy(sort);
        }

         let currPage = page;
         if (query.get('page')) {
            currPage = query.get('page');
            setPage(currPage);
         }

        await fetchItems(category, sort, currPage);
        setFetchingData(false);
    };

    const fetchItems = async (category, sort, currPage, isAppend) => {
      setFetchingData(true);
      var queryParams = [
           (category === false ? null :
               category
                ? `category=${category.id}`
                : currentCategory ? `category=${currentCategory.id}` : ''),

           (sort != null ? `sortBy=${sort}` : `sortBy=${sortBy}`),
           (currPage != null ? `page=${currPage}` : `page=${page}`)
     ];
      const response = await fetch(ApiEndpoints.ShopItems + '?' + queryParams.filter(x => x).join('&'));
      const data = await response.json();
      
      if (isAppend) {
          setItems(items.concat(data.items));
      }
      else {
        setItems([]);
        setCount(0);
        setTimeout(() => {
            setCount(data.count);
            setItems(data.items);
        }, 50);
     }

     setFetchingData(false);
    };

   const updateUrl = (category, sort) => {
       var queryParams = [
            (category === false ? null :
                category
                ? `category=${category.id}`
                : currentCategory
                     ? `category=${currentCategory.id}`
                     : ''),
 
            (sort ? `sortBy=${sort}` : `sortBy=${sortBy}`)
      ];
       var newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryParams.filter(x => x).join('&')}`;
       window.history.pushState({ path: newUrl }, '', newUrl);
    }

    const popularityRule = new schedule.RecurrenceRule();
    popularityRule.hour = 4;
    popularityRule.minute = 30;
    popularityRule.tz = 'Etc/UTC';
    const notifyUpdatePopularity = schedule.scheduleJob(popularityRule, function(){
      if (sortBy === 'mostPopular') setPopularityUpdateModal(true);
    });

    return (
         <Fragment>
            <div className="shop-content">
        <NavMenuShop />
        <section className="section-content padding-y">
        <div className="container">
        <div className="row">
            <aside className="col-md-3 mb-4 pb-3">
                <h2 className="current-category-title">{currentCategory?.name || 'All items'}</h2>
                <ShopFilters
                    filters={filters}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    initialCategory={initialCategory}
                    fetchItems={fetchItems}
                    updateUrl={updateUrl}
                    setPage={setPage}
                    fetchingData={fetchingData}
                />
            </aside> 
            <main className="col-md-9">
        <header className="mb-4 pb-3">
                <div className="form-inline row">
                    <div className="col-md-2 pe-0 align-content-center">
                    <h5 className="pretty-font mb-0">Sort by</h5>
                    </div>
                    <div className="col-md-10">
                    <select
                        className="mr-2 form-control pretty-font"
                        value={sortBy}
                        disabled={fetchingData}
                        onChange={(e) => {
                            setSortBy(e.target.value);
                            setPage(0);
                            fetchItems(null, e.target.value, 0);
                            updateUrl(null, e.target.value);
                        }}
                    >
                        <option value="recommended">Recommended</option>
                        <option value="latest">Latest items</option>
                        <option value="mostPopular">Most Popular</option>
                        <option value="cheapest">Cheapest</option>
                    </select>
                    </div>
                </div>
        </header>
        {
            count === 0
                ? <div className="pretty-font">No items found</div>
                : <span className="mr-md-auto float-right pretty-font">{count} Items found</span>

        }
        <InfiniteScroll
          className="mt-3"
          dataLength={items.length}
          next={() => {
                const currPage = +page + 1;
                setPage(currPage);
                fetchItems(null, null, currPage, true);
          }}
          hasMore={items.length < count}
          loader={
              <div className="d-flex justify-content-center align-items-center pretty-font">
                <img className="shop-items-loader-img" src={SpinnerImg} />
                <span>Loading...</span>
              </div>}
         >
        <div className="row">
            {
                items.map((item, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <a className="text-decoration-none text-dark" href={`/shop/${item.id}`}>
                            <ShopItemPreview
                              title={item.name}
                              image={ApiEndpoints.PublicFile + "/" + item.thumbnailFileName}
                              oldPrice={item.price}
                              newPrice={item.discountedPrice}
                              id={item.id}
                              setCurrentCartItem={setCurrentCartItem}
                              quantity={item.stockQuantity}
                              setShopExceedsQuantity={setShopExceedsQuantityModal}
                              setCartLimitReached={setShopCartLimitReachedModal}
                            />
                        </a>
                    </div>))
            }
        </div> 
        </InfiniteScroll>
        {
            currentCartItem && <ShopCartAddedModal item={currentCartItem} setItem={setCurrentCartItem} />
        }
        {
            shopExceedsQuantityModal && <ShopCartExceedsStockQuantity setShopExceedsQuantity={setShopExceedsQuantityModal} />
        }
        {
            shopCartLimitReachedModal && <ShopCartExceedsLimit setShopLimitReached={setShopCartLimitReachedModal} />
        }
        {
            popularityUpdateModal && <PopularityUpdateModal />
        }
        </main>
        </div>
        </div> 
        </section>
          </div>
         </Fragment>
     );
}

import React, { Fragment, useEffect, useState, useRef, useReducer } from 'react';
import { NavMenuShop } from '../../navMenu/NavMenuShop';
import './ShopCart.css';
import { CartStorage } from '../../../storage/cartStorage';
import { ShippingCountryStorage } from '../../../storage/shippingCountryStorage';
import { ApiEndpoints } from '../../../api/apiEndpoints';
import { QuantityInput } from './quantityInput/QuantityInput';
import { getFixedNumberPrice, calculateTotalPrice, calculateTotalDiscountedPrice } from '../../../functions/prices';
import { ShopCartItemsMissing } from './cartItemsMissing/ShopCartItemsMissing';
import { AppliedDeniedIcon } from '../../../assets/shop/AppliedDeniedIcon';
import { ShopOrderValidation } from './ShopOrderValidation';
import { ModalValidation } from '../../modal/validation/ModalValidation';

import PaymentMethodsIcon from '../../../assets/shop/payment_methods.png';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import authService from '../../api-authorization/AuthorizeService';
import {ShippingCountryModal} from './shippingCountryModal/ShippingCountryModal';
import {ShippingDetailsModal} from './shippingDetailsModal/ShippingDetailsModal';
import Select from 'react-select';
import {calculateOptimalShippingMethod} from './OptimalShippingMethodsCalculator';

const mdParser = new MarkdownIt();
export const MaxItemsInCart = 20;
export const ShopCart = ({ }) => {
    const [shopItems, setShopItems] = useState([]);
    const [totalItemsPrice, setTotalItemsPrice] = useState(0);
    
    const [expandedItems, setExpandedItems] = useState({});
    const [showButtons, setShowButtons] = useState({});
    const [shopItemsMissing, setShopItemsMissing] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasUnfinishedOrders, setHasUnfinishedOrders] = useState(false);
    const itemRefs = useRef({});
    const imgRefs = useRef({});
    const cart = new CartStorage();

    const [shipCountry, setShipCountry] = useState(new ShippingCountryStorage().getCountry());
    const [shippingMethods, setShippingMethods] = useState([]);
    const [selectedShippingMethods, setSelectedShippingMethods] = useState({});

    const [shippingMode, setShippingMode] = useState(false);
    const [shippingCountryModalOpened, setShippingCountryModalOpened] = useState(false);
    const [shippingDetailsModalOpened, setShippingDetailsModalOpened] = useState(false);
    const [shippingDetails, setShippingDetails] = useState({});

    const [promoCode, setPromoCode] = useState('');
    const [appliedPromoCode, setAppliedPromoCode] = useState('');
    const [promoCodeState, setPromoCodeState] = useState(0); // 0 - not checked, 1 - valid, 2 - invalid
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(true);
    const validator = new ShopOrderValidation();

    const [refreshComponent, setRefreshComponent] = useState(false);
    const forceComponentUpdate = () => {
        setRefreshComponent(true);
        setTimeout(() => {
            setRefreshComponent(false);
        }, 0);
    }

    useEffect(() => { forceComponentUpdate(); }, [shippingMode, shopItems, error]);

    useEffect(async () => {
        if (!shipCountry) return;
        const response = await fetch(`${ApiEndpoints.ShippingMethods}/${shipCountry?.id}`);
        const data = await response.json();
        setShippingMethods(data);
    }, [shipCountry]);

    useEffect(async () => {
        const optimalShippings = calculateOptimalShippingMethod(shippingMethods, shopItems);
        for (const key of Object.keys(optimalShippings)) {
            selectShippingMethod(key, optimalShippings[key].deliveryId);
        }
    }, [shopItems, shippingMethods]);

    useEffect(async () => {
        document.title = 'Cart | Anna Shepeta';
        const authenticatedResult = await authService.isAuthenticated();
        setIsAuthenticated(authenticatedResult);
        if (authenticatedResult) {
            await fetchHasUnfinishedOrders();
        }

        await fetchItems();
        setSubmitting(false);
        
        const subscription = authService.subscribe(() => populateAuthState());
        return () => {
            authService.unsubscribe(subscription);
        }
    }, []);

    const populateAuthState = async () => {
        setIsAuthenticated(await authService.isAuthenticated());
    }

    const fetchHasUnfinishedOrders = async () => {
        const response = await fetch(ApiEndpoints.HasUnfinishedOrders);
        const data = await response.json();
        setHasUnfinishedOrders(data);
    }

    const fetchItems = async () => {
        const response = await fetch(`${ApiEndpoints.ShopCartItems}?ids=${Object.keys(cart.getItems()).join('&ids=')}${!!promoCode ? `&promoCode=${promoCode}` : ''}`);
        const data = await response.json();
        
        data.forEach(item => {
            let itemInCart = cart.getItems()[item.id];
            if (itemInCart > item.stockQuantity) {
                setShopItemsMissing(true);
                cart.updateItem(item.id, item.stockQuantity);
                itemInCart = cart.getItems()[item.id];
            }
        });
    
        for (const [key, value] of Object.entries(cart.getItems())) {
            if (!data.find(x => x.id === key)) {
                setShopItemsMissing(true);
                cart.removeItem(key);
            }
        }

        setShopItems(data.filter(x => cart.getItems()[x.id]));
    }

    const calculateShippingPrice = () => {
        return [...new Set(Object.keys(selectedShippingMethods).map(x => selectedShippingMethods[x]))]
            .filter(e => shippingMethods.find(x => x.id === e))
            .reduce((prev, curr) => {
                const method = shippingMethods.find(x => x.id === curr);
                return prev + method.price;
            }
        , 0);
    }

    useEffect(() => {
        setTimeout(() => {
             shopItems?.forEach(item => {
                const textElement = itemRefs.current[item.id];
                if (textElement && textElement.scrollHeight > 170 ) {
                    setShowButtons(prevState => ({
                        ...prevState,
                        [item.id]: true,
                    }));
                }
            });
        }, 0);
    }, [shopItems]);

    const toggleDescription = (itemId) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    const applyPromoCode = async () => {
        if (promoCodeState === 1 || promoCodeState === 2) return;
        if (!promoCode) {
            setPromoCodeState(2);
            return;
        }

        const response = await fetch(`${ApiEndpoints.CheckPromoCode}/${promoCode}`);
        const state = await response.json();
        setPromoCodeState(state ? 1 : 2);
        setAppliedPromoCode(state ? promoCode : '');
        await fetchItems();
    }

    const switchToShippingMode = () => {
        if (!shipCountry) {
            setShippingCountryModalOpened(true);
        }

        setShippingMode(true);
    }

    const selectShippingMethod = (itemId, deliveryId) => {
        setSelectedShippingMethods(prevState => ({
            ...prevState,
            [itemId]: deliveryId
        }));
    }

    const removeFromCart = (itemId) => {
        cart.removeItem(itemId);
        setShopItems(shopItems.filter(x => x.id !== itemId));
    }
    
    const createOrder = () => {
        const items = shopItems.map(item => {
            const itemInCart = cart.getItems()[item.id];
            if (!itemInCart) return null;
            return {
                itemId: item.id,
                quantity: itemInCart,
                deliveryId: selectedShippingMethods[item.id]
            };
        })
        .filter(x => !!x);

        return {
            countryId: shipCountry?.id,
            items: items,
            promoCode: appliedPromoCode,
            shippingDetails: shippingDetails
        };
    }

    const createAndValidateOrder = async (validateShippingDetails) => {
        const order = createOrder();
        const validationError = await validator.validate(order, validateShippingDetails);
        if (validationError) {
            setError(validationError);
            return null;
        }

        return order;
    }

    return (
        <Fragment>
        <div className="shop-cart">
        <NavMenuShop />
        <section className="h-100 gradient-custom">
          <div className="container">
            <div className="row d-flex justify-content-center my-4 flex-row-reverse">
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0">Summary</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        <span className="w-100">Products</span>
                        <span className="w-100 text-end notranslate">
                            {
                              calculateTotalPrice(shopItems, cart) === calculateTotalDiscountedPrice(shopItems, cart)
                                 ? null
                                 : <div className="d-inline text-danger" style={{marginRight: 5}}><s>{getFixedNumberPrice(calculateTotalPrice(shopItems, cart))} €</s></div>
                            }
                            <div className="d-inline">{getFixedNumberPrice(calculateTotalDiscountedPrice(shopItems, cart))} €</div>
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        <span className="w-100">Shipping</span>
                        <span className="w-100 text-end notranslate">
                            { getFixedNumberPrice(calculateShippingPrice()) + ' €' }
                        </span>
                      </li>
                       <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                          <span className="w-100">Promo code</span>
                          <span className={`w-100 text-end ${!isAuthenticated ? '' : 'd-none'}`}>
                              Log in first
                          </span>
                          {
                              isAuthenticated  && (
                                  <div className="input-group form-control p-0">
                                    <input
                                        type="text"
                                        className="form-control promo-input"
                                        placeholder="Enter promo code"
                                        value={promoCode}
                                        onChange={(e) => { setPromoCode(e.target.value); setPromoCodeState(0); }} />
                                    <button className="btn btn-secondary validate-promo" onClick={applyPromoCode}>
                                        <AppliedDeniedIcon
                                            isPending={promoCodeState == 0 ? true : false} 
                                            isApplied={promoCodeState == 1 ? true : false}
                                        />
                                    </button>
                                  </div>)
                          }
                       </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div className="w-100">
                          <strong>Total amount</strong>
                          <strong>
                            <p className="mb-0">(including VAT)</p>
                          </strong>
                        </div>
                        <span className="w-100 text-end notranslate">
                            {
                              calculateTotalPrice(shopItems, cart) === calculateTotalDiscountedPrice(shopItems, cart)
                                 ? null
                                 : <div className="d-inline text-danger" style={{marginRight: 5}}><s>{getFixedNumberPrice(calculateTotalPrice(shopItems, cart) + calculateShippingPrice())} €</s></div>
                            }
                            <div className="d-inline">{getFixedNumberPrice(calculateTotalDiscountedPrice(shopItems, cart) + calculateShippingPrice())} €</div>
                        </span>
                      </li>
                    </ul>
                    <p className={`text-center text-danger ${cart.getTotalCount() === 0 ? '' : 'd-none'}`}>Please add items to your cart to proceed</p>
                    <p className={`text-center text-danger ${cart.getTotalCount() > 0 && hasUnfinishedOrders ? '' : 'd-none'}`}>
                        You have unfinished order. Please complete or cancel it first
                        <a className="btn summary-btn btn-primary w-100 mt-2" href="/profile">Go to orders</a>
                    </p>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-primary w-100 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders && !isAuthenticated) ? '' : 'd-none'}`}
                        onClick={async () => {
                            setSubmitting(true);
                            await authService.signIn({ returnUrl: '/cart' });
                            forceComponentUpdate();
                            setSubmitting(false);
                        }}>
                        Log in to checkout
                    </button>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-primary w-100 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders && isAuthenticated && !shippingMode) ? '' : 'd-none'}`}
                        onClick={() => switchToShippingMode()}>
                        Select shipping
                    </button>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-primary w-100 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders && isAuthenticated && shippingMode) ? '' : 'd-none'}`}
                        onClick={async () => {
                        if (await createAndValidateOrder(false) != null) setShippingDetailsModalOpened(true);
                    }}>
                     Proceed to checkout
                    </button>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-primary w-100 mt-3 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders && isAuthenticated && shippingMode && !shipCountry) ? '' : 'd-none'}`}
                        onClick={() => switchToShippingMode()}
                    >
                      Select shipping country
                    </button>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-secondary w-100 mt-3 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders && isAuthenticated && shippingMode) ? '' : 'd-none'}`}
                        onClick={() => { setShippingMode(false); } }
                    >
                        Back to quantity selection
                    </button>
                    <button
                        disabled={submitting}
                        className={`btn summary-btn btn-secondary w-100 mt-3 ${(cart.getTotalCount() > 0 && !hasUnfinishedOrders  && isAuthenticated && shippingMode && shipCountry) ? '' : 'd-none'}`}
                        onClick={() => setShippingCountryModalOpened(true)}
                    >
                        Select another shipping country
                    </button>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <p><strong>Shipping information</strong></p>
                    <p className="mb-0">
                        {
                                [...new Set(Object.keys(selectedShippingMethods).map(x => selectedShippingMethods[x]))]
                                .filter(e => shippingMethods.find(x => x.id === e))
                                .map(e => {
                                  const method = shippingMethods.find(x => x.id === e);
                                  return (
                                      <p>
                                        <span className="fw-bold d-block">{method.name}</span>
                                        <span className="d-block">{method.description}</span>
                                      </p>
                                  );
                                })
                        }
                    </p>
                  </div>
                </div>
                <div className="card mb-4 mb-lg-0">
                  <div className="card-body">
                    <p><strong>We accept</strong></p>
                    <img
                      className="me-2 payment-methods"
                      src={PaymentMethodsIcon}
                      alt="Visa, Mastercard, American Express, Discover, JCB, UnionPay" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                      <h5 className="mb-0">
                          <span hidden={shippingMode}>{`Cart - ${cart.getTotalCount()} item${cart.getTotalCount() !== 1 ? 's' : ''} (Max ${MaxItemsInCart} items)`}</span>
                          <span hidden={!shippingMode}>{`Delivery to: ${shipCountry ? shipCountry.name : 'Not selected'}`}</span>
                     </h5>
                  </div>
                  <div className="card-body">
                      <ModalValidation error={error} />
                      {
                          shopItems.map(item => {
                                const isExpanded = expandedItems[item.id];
                                const shouldShowButton = showButtons[item.id];
                                let itemInCart = cart.getItems()[item.id];
                                return (
                                  <div className="cart-row row position-relative">
                                        <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                          <div className="bg-image hover-overlay hover-zoom ripple rounded" data-mdb-ripple-color="light">
                                            <img
                                                ref={el => imgRefs.current[item.id] = el}
                                                src={ApiEndpoints.PublicFile + '/' + item.thumbnailFileName}
                                                className="item-img w-100"
                                            />
                                          </div>
                                        </div>
                          
                                        <div
                                            className="item-text col-lg-5 col-md-12 mb-4 mb-lg-0"
                                            ref={el => itemRefs.current[item.id] = el}
                                            style={{
                                                maxHeight: isExpanded ? '2000px' : '170px',
                                                overflow: 'hidden'
                                            }}
                                        >
                                          <p><a className="item-title" href={`/shop/${item.id}`}><strong>{item.name}</strong></a></p>
                                          <MdEditor
                                              renderHTML={text => mdParser.render(item.description)}
                                              value={item.description}
                                              readOnly={true}
                                              view={{ html: true, menu: false, md: false }}
                                          />
                                          {shouldShowButton && (
                                              <button className="toggle-text-btn" onClick={() => toggleDescription(item.id)}>
                                                  {isExpanded ? 'Show less' : 'Show more'}
                                              </button>
                                          )}
                                        </div>
                          
                                        <div className="col-lg-4 mb-4 mb-lg-0">
                                                <div className={'item-controls' + (shippingMode ? ' d-none' : '')}>
                                                    <div className="d-flex mb-4">
                                                        <div data-mdb-input-init className="form-outline quantity-input">
                                                          <label className="form-label w-100 text-center">
                                                              Quantity ({item.stockQuantity} in stock)
                                                          </label>
                                                          <QuantityInput
                                                                initialQuantity={itemInCart || 0}
                                                                updateStorageQuantity={(quantity) => {
                                                                    if (quantity === undefined) return;
                                                                    if (itemInCart) {
                                                                        cart.updateItem(item.id, quantity);
                                                                    } else if (quantity > 0) {
                                                                        cart.addItem(item.id, quantity);
                                                                    }
                                              
                                                                    forceComponentUpdate();
                                                                }}
                                                                maxQuantity={Math.min(item.stockQuantity, (MaxItemsInCart - cart.getTotalCount()) + (itemInCart || 0))}
                                                          />
                                                        </div>
                                                      </div>
                                                     <label className="form-label w-100 text-center">Subtotal</label>
                                                     <p className="text-md-center notranslate" style={{ height: 46 }}>
                                                        {
                                                           item.price === item.discountedPrice
                                                              ? null
                                                              : <h5 className="d-inline text-danger old-price"><s>{getFixedNumberPrice(item.price * itemInCart)} €</s></h5>
                                                        }
                                                        <h3>{getFixedNumberPrice(item.discountedPrice * itemInCart)} €</h3>
                                                     </p>
                                                </div>
                                                <div className={'item-controls' + (!shippingMode ? ' d-none' : '')}>
                                                    <div className="d-flex mb-4 w-100 flex-column">
                                                      {
                                                          refreshComponent ? null :
                                                          shippingMethods.filter(x => item.deliveriesIds?.includes(x.id)).length === 0
                                                            ? 
                                                            <>
                                                                <p className="text-center text-danger">No shipping methods available</p>
                                                                <p className="no-shipping-solution">If you want to buy this item, please contact us – we’ll be happy to explore shipping options together!</p>
                                                                <button onClick={() => removeFromCart(item.id)} className="btn btn-danger w-100">
                                                                     Remove from cart
                                                                </button>
                                                            </>
                                                            :
                                                            <>
                                                                <label className="form-label w-100 text-center">Shipping method</label>
                                                                <Select
                                                                    value={selectedShippingMethods[item.id] && shippingMethods.find(x => x.id === selectedShippingMethods[item.id]) && {
                                                                        value: selectedShippingMethods[item.id],
                                                                        label: shippingMethods.find(x => x.id === selectedShippingMethods[item.id]).name }}
                                                                  onChange={(e) => selectShippingMethod(item.id, e.value)}
                                                                  className="w-100 text-center"
                                                                  options={shippingMethods.filter(x => item.deliveriesIds?.includes(x.id)).map(x => ({ value: x.id, label: x.name }))}
                                                                />
                                                              {
                                                                selectedShippingMethods[item.id] && shippingMethods.find(x => x.id === selectedShippingMethods[item.id]) && (
                                                                    <div className="mt-4">
                                                                        <label className="form-label w-100 text-center">Shipping price</label>
                                                                        <p className="shipping-price-label">
                                                                            <span className="notranslate">{getFixedNumberPrice(shippingMethods.find(x => x.id === selectedShippingMethods[item.id]).price)} €</span> first item
                                                                            <br/>
                                                                            <span className="notranslate">0.00 €</span> each next item
                                                                        </p>
                                                                    </div>
                                                                    )
                                                              }
                                                            </>
                                                      }
                                                      
                                                    </div>
                                                </div>
                                        </div>
                                      </div>
                                );
                            }).reduce((prev, curr) => prev ? [prev, (<hr className="my-4" />), curr] : curr, '') || <p>Your cart is empty</p>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        {shopItemsMissing && <ShopCartItemsMissing setShowModal={setShopItemsMissing} />}
        {shippingCountryModalOpened &&
            <ShippingCountryModal
                setShowModal={setShippingCountryModalOpened}
                setShipCountry={setShipCountry}
            />
        }
        {shippingDetailsModalOpened &&
            <ShippingDetailsModal
                setShowModal={setShippingDetailsModalOpened}
                createAndValidateOrder={createAndValidateOrder}
                error={error}
                setError={setError}
                shippingDetails={shippingDetails}
                setShippingDetails={setShippingDetails}
                cart={cart}
            />
        }
        </Fragment>
    );    
}
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NavMenuShop } from '../../navMenu/NavMenuShop';
import authService from '../../api-authorization/AuthorizeService';
import { ApiEndpoints } from '../../../api/apiEndpoints';
import SpinnerImg from '../../../assets/spinner.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import { getFixedNumberPrice } from '../../../functions/prices';
import { formatDateToDisplay } from '../../../functions/dates';
import { InconsistentStatuesModal } from './inconsistentStatuesModal/InconsistentStatuesModal';
import { OrderStatusBadge } from '../orderDetails/orderStatusBadge/OrderStatusBadge';
import './MyProfile.css';

export function MyProfile() {
    const query = new URLSearchParams(useLocation().search);
    const [submitting, setSubmitting] = useState(true);
    const [userInfo, setUserInfo] = useState(null);

    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(-1);
    const [ordersPage, setOrdersPage] = useState(0);

    const orderRefs = useRef({});
    const imgRefs = useRef({});

    const [showExpandButtons, setShowExpandButtons] = useState({});
    const [expandedItems, setExpandedItems] = useState({});
    
    const [isInconsistentStatuesModalVisible, setIsInconsistentStatuesModalVisible] = useState(false);

    useEffect(() => {
            setTimeout(() => {
                 orders?.forEach((item, index) => {
                    const textElement = orderRefs.current[index];
                    if (textElement && textElement.scrollHeight > 170 ) {
                        setShowExpandButtons(prevState => ({
                            ...prevState,
                            [index]: true,
                        }));
                    }
                });
            }, 0);
        }, [orders]);

    const toggleOrder = (id) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    useEffect(async () => {
            document.title = 'Your Profile | Anna Shepeta';

            if (query.get('refreshState')) {
                await authService.updateUserState();
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            const subscription = authService.subscribe(() => populateAuthState());
            if (!await authService.isAuthenticated()) {
                setSubmitting(true);
                await authService.signIn({ returnUrl: '/profile' });
            }

            if (await authService.isAuthenticated()) {
                populateAuthState();
            }
        
            await fetchOrders(0, false);
            return () => {
                authService.unsubscribe(subscription);
            }
    }, []);

    const populateAuthState = async () => {
        setUserInfo(await authService.getUser());
        setSubmitting(false);
    }

    useEffect(() => {
        forceComponentUpdate();
    }, [userInfo]);

    const [refreshComponent, setRefreshComponent] = useState(false);
    const forceComponentUpdate = () => {
        setRefreshComponent(true);
        setTimeout(() => {
            setRefreshComponent(false);
        }, 0);
    }

    const handleSubscribeToNewsletters = async () => {
        setSubmitting(true);
        await fetch(`${ApiEndpoints.Users}/subscribeNewsletter/${userInfo?.newsletters === "1" ? false : true}`,
        {
            method: 'POST'
        });

        window.location.href = '/profile?refreshState=true';
    }

    const fetchOrders = async (page, isAppend) => {
        const response = await fetch(`${ApiEndpoints.ShopOrders}?page=${page}`);
        const data = await response.json();
        setOrders(isAppend ? orders.concat(data.items) : data.items);
        setOrdersCount(data.count);

        data.items.forEach((item, index) => {
           if(item.status === 'New' && !item.paymentLink) { setIsInconsistentStatuesModalVisible(true); }
        });
    }

    const cancelOrder = async (paymentSessionId) => {
        setSubmitting(true);
        await fetch(ApiEndpoints.CancelPayment, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(paymentSessionId)
        });

        await fetchOrders(0, false);
        setSubmitting(false);
    }

    return (
        <div className="shop-profile">
            <NavMenuShop />
            <section>
              <div className="container">
                  <div className="row">
                      <div className="col-md-4">
                          <div className="card mb-4">
                            
                            <div className="card-body">
                                {
                                    refreshComponent || !userInfo ? null :
                                    <div className="profile-card">
                                        <h2 className="notranslate">{userInfo?.given_name}</h2>
                                        <h2 className="notranslate">{userInfo?.family_name}</h2>
                                        <div className="mt-4">
                                            <p>Email: <span className="notranslate">{userInfo?.email}</span></p>
                                            <p>Newsletters subscription: {userInfo?.newsletters === "1" ? '✅' : '❌'}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                          </div>
                           <div className="card mb-4">
                              <div className="card-body">
                                  <button
                                      disabled={submitting}
                                      className="btn btn-danger w-100 mb-2"
                                      onClick={async () => await authService.signOut()}
                                  >
                                      Logout
                                  </button>
                                  <a
                                    className={`btn btn-primary w-100 mb-2 ${submitting ? 'disabled-link' : ''}`}
                                    href="/authentication/profile"
                                  >
                                      Change name
                                  </a>
                                  <a
                                      className={`btn btn-primary w-100 mb-2 ${submitting ? 'disabled-link' : ''}`}
                                      href="/authentication/changePassword"
                                  >
                                    Change password
                                  </a>
                                  <button
                                   disabled={submitting}
                                   className="btn btn-primary w-100"
                                   onClick={handleSubscribeToNewsletters}
                                  >
                                       {
                                         userInfo?.newsletters === "1"
                                            ? 'Unsubscribe from newsletters'
                                            : 'Subscribe to newsletters'
                                       }
                                  </button>
                                  {userInfo?.newsletters !== "1" &&
                                        <small className="text-muted">
                                            Stay in touch for updates from me, only when there's something special to share – no spam, just love 💌
                                        </small>
                                  }
                              </div>
                            </div>
                      </div>
                      <div className="col-md-8">
                        <div className="card mb-4">
                            <div className="card-header py-3">
                            <h5 className="mb-0">My shop orders</h5>
                            </div>
                            <div className="card-body" style={{ marginBottom: 9 }}>
                                {   ordersCount === -1 ? <p>Loading...</p> :
                                    ordersCount === 0
                                    ? <p>You haven't made any orders yet 🥺</p>
                                    :
                                     <InfiniteScroll
                                        dataLength={orders.length}
                                        next={() => {
                                          const newPage = +ordersPage + 1;
                                          setOrdersPage(newPage);
                                          fetchOrders(newPage, true);
                                        }}
                                        hasMore={orders.length < ordersCount}
                                        loader={
                                            <div className="d-flex justify-content-center align-items-center pretty-font order-items-loader">
                                              <img className="loader-img" src={SpinnerImg} />
                                              <span>Loading...</span>
                                            </div>}
                                     >
                                     {
                                            orders.map((order, index) => {
                                                const shouldShowExpandButton = showExpandButtons[index];
                                                const isExpanded = expandedItems[index];
                                                return (
                                                    <div key={index} className={`row position-relative ${ordersPage == 0 ? 'order-row-animated' : ''}`} >
                                                        <div className="d-flex justify-content-between w-100">
                                                            <span class="badge badge-secondary order-badge">{formatDateToDisplay(new Date(order.creation))}</span>
                                                            <OrderStatusBadge status={order.status} />
                                                        </div>
                                                        <div className="row m-0 flex-md-row-reverse">
                                                            <div className="col-md-4 text-end p-0 m-0">
                                                                <div className="order-buttons d-flex flex-column justify-content-center">
                                                                    {
                                                                        order.paymentLink &&
                                                                        <a
                                                                            disabled={submitting}
                                                                            className="btn summary-btn btn-primary w-100 mt-2"
                                                                            href={order.paymentLink}
                                                                        >
                                                                            Pay the order
                                                                        </a>
                                                                    }
                                                                    {
                                                                        order.paymentSessionId &&
                                                                        <button
                                                                            disabled={submitting}
                                                                            className="btn summary-btn btn-danger w-100 mt-2"
                                                                            onClick={() => cancelOrder(order.paymentSessionId)}
                                                                        >
                                                                                Cancel the order
                                                                        </button>
                                                                    }
                                                                    <a className="btn summary-btn btn-secondary w-100 mt-2" href={`/order/${order.id}`}>Details</a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-md-8 p-0 m-0 items-container"
                                                                ref={el => orderRefs.current[index] = el}
                                                                style={{
                                                                        maxHeight: isExpanded ? '2500px' : '170px',
                                                                        overflow: 'hidden'
                                                                }}
                                                            >
                                                                {
                                                                    order.items.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className="order-item d-flex flex-row" >
                                                                                <a href={`/shop/${item.itemId}`} className="text-decoration-none">
                                                                                    <img className="order-item-preview" src={ApiEndpoints.PublicFile + '/' + item.thumbnailFileName} />
                                                                                </a>
                                                                                <div className="w-100 d-flex flex-column">
                                                                                    <h6 className="m-0 flex-grow-1">
                                                                                        <a href={`/shop/${item.itemId}`} className="text-decoration-none text-dark">
                                                                                         {item.name}
                                                                                        </a>
                                                                                    </h6>
                                                                                    <p className="m-0 order-item-info">
                                                                                        Price:
                                                                                        <span className="notranslate"> {getFixedNumberPrice(item.discountedPrice)} €</span>
                                                                                    </p>
                                                                                    <p className="m-0 order-item-info">Quantity: {item.quantity}</p>
                                                                                </div>
                                                                            </div>
                                                                        )})
                                                                    
                                                                }
                                                            </div>
                                                        </div>
                                                        {shouldShowExpandButton && (
                                                          <button className="toggle-text-btn" onClick={() => toggleOrder(index)}>
                                                              {isExpanded ? 'Show less' : 'Show more'}
                                                          </button>
                                                        )}
                                                    </div>
                                                );
                                            }).reduce((prev, curr) => prev ? [prev, (<hr className="my-4" />), curr] : curr, '')
                                     }
                                     </InfiniteScroll>
                                 }
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </section>
            {
                isInconsistentStatuesModalVisible &&
                   <InconsistentStatuesModal setShowModal={setIsInconsistentStatuesModalVisible} />
            }
        </div>
    );    
}
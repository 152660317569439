import React, { useState, useEffect } from 'react';
import { ExpandSvgIcon } from '../../../../assets/shop/ExpandSvgIcon';
import { TrashSvgIcon } from '../../../../assets/shop/TrashSvgIcon';

export const QuantityInput = ({initialQuantity, updateStorageQuantity, maxQuantity}) => {
   const [quantity, setQuantity] = useState(initialQuantity);
   
   useEffect(() => {
        updateStorageQuantity(quantity);
   }, [quantity]);

   const handleClear = () => {
        setQuantity(0);
   };

  const handleDecrease = () => {
    setQuantity(prev => (prev > 0 ? prev - 1 : 0));
  };

  const handleIncrease = () => {
    setQuantity(prev => (prev < maxQuantity ? prev + 1 : maxQuantity));
  };

  return (
      <div className="input-group">
        <button
          data-mdb-button-init
          data-mdb-ripple-init
          className="btn btn-secondary d-flex align-items-center"
          onClick={handleDecrease}
        >
          <ExpandSvgIcon styles={{ height: 20, width: 20, fill: 'white' }} />
        </button>

        <input
          name="quantity"
          value={quantity}
          type="number"
          className="form-control"
          readonly
          disabled
        />

        <button
          data-mdb-button-init
          data-mdb-ripple-init
          className="btn btn-secondary d-flex align-items-center"
          onClick={handleIncrease}
        >
          <ExpandSvgIcon styles={{ height: 20, width: 20, transform: 'rotate(180deg)', fill: 'white' }} />
        </button>
        <button type="button" className="btn btn-danger float-end" onClick={handleClear}>
            <TrashSvgIcon styles={{ height: 20, width: 20, fill: 'white' }} />
        </button>
      </div>
  );
};

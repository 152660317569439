import React, { Component, Fragment, useState, useRef } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalValidation } from '../../../modal/validation/ModalValidation';
import './ShippingDetailsModal.css';

export function ShippingDetailsModal({ setShowModal, shippingDetails, setShippingDetails, createAndValidateOrder, error, setError, cart }) {
    const modalWindowRef = useRef();
    const [submitting, setSubmitting] = useState(false);
    
    const handleChangeValue = (e) => {
        const { name, value } = e.target;
        setShippingDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    
    const checkOutAndPay = async () => {
        if (submitting) return;
        setSubmitting(true);

        const order = await createAndValidateOrder(true);
        if (order) {
            const response = await fetch(ApiEndpoints.ShopOrders, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(order),
            });
        
            const data = await response.text();
            if (data.startsWith("https://checkout.stripe.com")) {
                cart.clearCart();
                window.location.href = data;
            }
            else {
                setError(data);
                setSubmitting(false);
            }
        } else {
            setSubmitting(false);
        }
    }

    return (
        <div className="shipping-details-modal">
            <ModalWindow
                ref={modalWindowRef}
                title="Shipping details"
                onClose={() => { setShowModal(false) }}
            >
               <ModalValidation error={error} />
               <div className="form-group">
                  <label htmlFor="address1">
                      Address line 1
                      <div className="required d-inline"><RequiredSvgIcon /></div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address1"
                    name="address1"
                    onChange={handleChangeValue}
                    value={shippingDetails.address1}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address1">
                      Address line 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address2"
                    name="address2"
                    onChange={handleChangeValue}
                    value={shippingDetails.address2}
                  />
                </div>
                <div className="d-flex flex-row">
                    <div className="form-group w-100 me-2">
                      <label htmlFor="city">
                          City
                          <div className="required d-inline"><RequiredSvgIcon /></div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        onChange={handleChangeValue}
                        value={shippingDetails.city}
                      />
                    </div>
                    <div className="form-group w-100 ms-2">
                      <label htmlFor="province">
                          Province
                          <div className="required d-inline"><RequiredSvgIcon /></div>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="province"
                        name="province"
                        onChange={handleChangeValue}
                        value={shippingDetails.province}
                      />
                    </div>
                </div>
                <div className="form-group w-50 pe-2">
                  <label htmlFor="postalCode">
                      Postal / Zip code
                      <div className="required d-inline"><RequiredSvgIcon /></div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    name="postalCode"
                    onChange={handleChangeValue}
                    value={shippingDetails.postalCode}
                  />
                </div>
                <div className="form-group">
                    <label htmlFor="comments">
                        Comments
                    </label>
                    <textarea
                      className="form-control"
                      id="comments"
                      name="comments"
                      onChange={handleChangeValue}
                      value={shippingDetails.comments}
                    />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Close window</button>
                  <button disabled={submitting} type="button" className="btn btn-primary" onClick={() => {checkOutAndPay()}}>Checkout and pay</button>
                </div>
            </ModalWindow>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import SuccessPaymentImg from '../../../../assets/shop/SuccessPayment.png';
import './SuccessPayment.css';

export const SuccessPayment = () => {
    return (
        <div className="success-payment">
            <NavMenuShop />
            <div className="container">
                <div class="d-flex align-items-center">
                        <img src={SuccessPaymentImg} alt="Success payment" style={{ maxWidth: 300 }} />
                        <div class="confirmation-text">
                            <h1>Thank you for purchase!</h1>
                            <p>Your order has been successfully paid, and it will soon be prepared for shipment. Each item is made with love and care, and I hope it brings you joy.</p>
                            <p>Stay tuned for updates — your order will be on its way to you soon! If you have any questions, I'm here to help, just press on the mail icon.</p>
                            <a href="/profile" className="text-primary mb-5">My Orders</a>
                        </div>
                    </div>
            </div>
        </div>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import { ShopItemPreview } from '../../item/preview/ShopItemPreview';
import { ShopItemCreateEdit } from './ShopItemCreateEdit';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ListManager } from "react-beautiful-dnd-grid";
import { ShopItemDeleteModal } from './ShopItemDeleteModal';
import './ShopItemsList.css';
import SpinnerImg from '../../../../assets/spinner.gif';
import InfiniteScroll from "react-infinite-scroll-component";

export function ShopItemsList() {
      const itemInitialState = {
           id: '',
           name: '',
           description: '',
           price: 0,
           stockQuantity: 0,
           assets: [],
           categories: [],
           archived: false
     };

      const [showModal, setShowModal] = useState(false);
      const [items, setItems] = useState([]);
      const [categories, setCategories] = useState([]);
      const [deliveries, setDeliveries] = useState([]);
      const [currentItem, setCurrentItem] = useState(itemInitialState);
      const [isEditing, setIsEditing] = useState(false);
      const [deleteItemId, setDeleteItemId] = useState(null);
      const [page, setPage] = useState(0);
      const [count, setCount] = useState(0);

      useEffect(() => {
          fetchItems();
          fetchCategories();
          fetchDeliveries();
      }, []);
  
      const fetchItems = async (currPage, isAppend) => {
          var queryParams = [
             (currPage != null ? `page=${currPage}` : `page=${page}`)
          ];

          const response = await fetch(ApiEndpoints.ManageShopItems + '?' + queryParams.filter(x => x).join('&'));
          const data = await response.json();
          setItems(isAppend ? items.concat(data.items) : data.items);
          setCount(data.count);
      };
  
      const fetchCategories = async () => {
          const response = await fetch(ApiEndpoints.ManageShopItemCategories);
          const data = await response.json();
          setCategories(data.map((category) => ({ value: category.id, label: category.name })));
      };
  
      const fetchDeliveries = async () => {
        const response = await fetch(ApiEndpoints.ManageShopDeliveries);
        const data = await response.json();
        setDeliveries(data.map((delivery) => ({
            value: delivery.id,
            label: delivery.name + (delivery.internalName ? ` (${delivery.internalName})` : '')
        })));
      };

      const handleCreate = () => {
          setCurrentItem(itemInitialState);
          setIsEditing(false);
          setShowModal(true);
      };
  
      const handleEdit = (item) => {
          setCurrentItem(item);
          setIsEditing(true);
          setShowModal(true);
      };
  
      const handleDelete = (id) => {
          setDeleteItemId(id);
      };
  
     const handleDragEnd = (sourceIndex, destinationIndex) => {
         if (destinationIndex === null || sourceIndex === destinationIndex) {
             return;
         }

         const updatedItems = Array.from(items);
         const [movedItem] = updatedItems.splice(sourceIndex, 1);
         updatedItems.splice(destinationIndex, 0, movedItem);
         
         var orderUpdates = [];
         if (sourceIndex > destinationIndex) {
                const temp = sourceIndex;
                sourceIndex = destinationIndex;
                destinationIndex = temp;
         }

         for (let i = sourceIndex; i <= destinationIndex; i++) {
             orderUpdates.push({ id: updatedItems[i].id, newOrder: items[i].order });
         }

         if (orderUpdates.length > 0) {
             fetch(ApiEndpoints.ReorderShopItems, {
                 method: 'POST',
                 headers: { 'Content-Type': 'application/json' },
                 body: JSON.stringify(orderUpdates),
             }).then(() => {
                 setPage(0);
                 fetchItems(0);
             });
         }
     };

      return (
       <Fragment>
          <div className="item-manager">
              <NavMenuShop />
              <section className="section-content padding-y">
              <div className="container">
              <button className="btn btn-primary mb-3 pretty-font add-asset-btn" onClick={handleCreate}>Create item</button>
              <span className="mr-md-auto float-right pretty-font float-end">{count} Items found</span>
              <div className="row col-lg-9 m-auto">
                  <InfiniteScroll
                    dataLength={items.length}
                    next={() => {
                          const currPage = +page + 1;
                          setPage(currPage);
                          fetchItems(currPage, true);
                    }}
                    hasMore={items.length < count}
                    loader={
                        <div className="d-flex justify-content-center align-items-center pretty-font">
                          <img className="shop-items-loader-img" src={SpinnerImg} />
                          <span>Loading...</span>
                        </div>}
                   >
                  <ListManager
                      items={items}
                      direction="horizontal"
                      maxItems={3}
                      onDragEnd={handleDragEnd}
                      render={item => (
                               <div className={item.archived ? "archived-item" : ""} onClick={() => handleEdit(item)}>
                               <ShopItemPreview
                                      title={item.name}
                                      image={item.assets && item.assets.length > 0 && ApiEndpoints.PublicFile + "/" + item.assets[0].thumbnailFileName }
                                      oldPrice={item.price}
                                      newPrice={item.price}
                                      id={item.id}
                                      quantity={item.stockQuantity}
                                      setCartLimitReached={() => {}}
                                      setShopExceedsQuantity={() => {}}
                                      setCurrentCartItem={() => {}}
                               />
                            </div>
                          )}
                    />
                  </InfiniteScroll>
                </div>
              </div> 
              </section>
              {showModal && (
                  <ShopItemCreateEdit
                       isEditing={isEditing}
                       setShowModal={setShowModal}
                       categories={categories}
                       deliveries={deliveries}
                       item={currentItem}
                       fetchItems={fetchItems}
                       handleDelete={handleDelete}
                       setPage={setPage}
                  />
              )}
              {deleteItemId && (
                  <ShopItemDeleteModal
                      deleteItemId={deleteItemId}
                      setDeleteItemId={setDeleteItemId}
                      fetchItems={fetchItems}
                      setPage={setPage}
                  />
              )}
          </div>
       </Fragment>
      );
}
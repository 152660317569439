import React, { useRef, useState } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import AsyncSelect from 'react-select/async';
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';
import { ModalWindow } from '../../../modal/ModalWindow';
import { ModalValidation } from '../../../modal/validation/ModalValidation';
import { ShopDeliveryCreateEditValidation } from './ShopDeliveryCreateEditValidation';

export function ShopDeliveryCreateEditModal({currentDelivery, setCurrentDelivery, setShowModal, fetchDeliveries, isEditing}) {
  const modalWindowRef = useRef();
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const validator = new ShopDeliveryCreateEditValidation();

  const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) return;
        setSubmitting(true);

        const validationError = await validator.validate(currentDelivery);
        if (validationError) {
            setError(validationError);
            document.getElementsByClassName("modal")[0].scrollTo(0, 0);
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        for (const key of Object.keys(currentDelivery)) {
            if (!['countries'].includes(key)) {
                formData.append(key, currentDelivery[key]);
            } else {
                currentDelivery[key] && currentDelivery[key].forEach((selectItem, index) => {
                    formData.append(`${key}[${index}]`, selectItem.id);
                });
            }
        }

        await fetch(ApiEndpoints.ManageShopDeliveries + (isEditing ? `/${currentDelivery.id}` : ''), {
            method: isEditing ? "PUT" : "POST",
            body: formData,
        });

        fetchDeliveries();
        modalWindowRef.current.closeWindow();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentDelivery(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

  const handleSelectChange = (name, selectedOptions) => {
    const selectedValues = selectedOptions?.map(option => ({ id: option.value }));
    setCurrentDelivery(prevState => ({ ...prevState, [name]: selectedValues }));
  };

  const suggestCountries = async (filter) => {
    const response = await fetch(ApiEndpoints.SuggestCountriesForDeliveries + `?filter=${filter}`);
    const data = await response.json();
    return data.map((country) => ({ value: country.id, label: country.name }));
  };

  return (
       <ModalWindow
            ref={modalWindowRef}
            title={isEditing ? 'Edit Delivery' : 'Create Delivery'}
            onClose={() => setShowModal(false)}>
               <form onSubmit={handleSubmit}>
                 <ModalValidation error={error} />
                 <div className="form-group">
                   <label htmlFor="name">
                       Name
                       <div className="required d-inline"><RequiredSvgIcon /></div>
                   </label>
                   <input
                     type="text"
                     className="form-control"
                     id="name"
                     name="name"
                     value={currentDelivery.name}
                     onChange={handleInputChange}
                   />
                 </div>
                 <div className="form-group">
                    <label htmlFor="internalName">
                        Internal name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="internalName"
                      name="internalName"
                      value={currentDelivery.internalName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                   <label htmlFor="price">
                       Price
                       <div className="required d-inline"><RequiredSvgIcon /></div>
                   </label>
                   <input
                     type="number"
                     className="form-control"
                     id="price"
                     name="price"
                     value={currentDelivery.price}
                     onChange={handleInputChange}
                   />
                </div>
                 <div className="form-group">
                    <label htmlFor="description">
                        Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={currentDelivery.description}
                      onChange={handleInputChange}
                    />
                 </div>
                 <div className="form-group">
                     <label htmlFor="countries" className="checkbox-label">Countries</label>
                     <AsyncSelect
                        name="countries"
                        id="countries"
                        loadOptions={suggestCountries}
                        isMulti
                        onChange={(selectedOptions) => handleSelectChange('countries', selectedOptions)}
                        defaultValue={currentDelivery.countries?.map(country => ({ value: country.id, label: country.name }))}
                     />
                 </div>

                 <button type="submit" className="btn btn-primary" disabled={submitting}>
                   {isEditing ? 'Save Changes' : 'Create Delivery'}
                 </button>
           </form>
       </ModalWindow>
    );
 }
export const fetchHandleLoginRedirect = (loginEndpoint, clearStateFunc) => {
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
        try {
            const response = await originalFetch(...args);
            if (response.status === 401) {
                clearStateFunc();
                window.location.href = loginEndpoint;
            }

            return response;
        } catch (error) {
            throw error;
        }
    };
}
export const getFixedNumberPrice = (price) => {
    return (price || 0).toFixed(2);
}

export const calculateTotalPrice = (shopItems, cart) => {
    let total = 0;
    for (const shopItem of shopItems) {
        if (cart.getItems()[shopItem.id]) {
            total += shopItem.price * cart.getItems()[shopItem.id];
        }
    }
    
    return total;
}

export const calculateTotalDiscountedPrice = (shopItems, cart) => {
    let total = 0;
    for (const shopItem of shopItems) {
        if (cart.getItems()[shopItem.id]) {
            total += shopItem.discountedPrice * cart.getItems()[shopItem.id];
        }
    }
    
    return total;
}
import React, { useState, useEffect } from 'react';
import { ApiEndpoints } from '../../../api/apiEndpoints';
import { NavMenuShop } from '../../navMenu/NavMenuShop';
import { getFixedNumberPrice } from '../../../functions/prices';
import { formatDateToDisplay } from '../../../functions/dates';
import { OrderStatusBadge } from './orderStatusBadge/OrderStatusBadge';
import { InconsistentStatuesModal } from '../myProfile/inconsistentStatuesModal/InconsistentStatuesModal';
import './OrderDetails.css';

export function OrderDetails({ orderId }) {
    document.title = 'Order Details | Anna Shepeta';
    const [shopOrder, setShopOrder] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [isInconsistentStatuesModalVisible, setIsInconsistentStatuesModalVisible] = useState(false);

    useEffect(() => {
        fetch(`${ApiEndpoints.ShopOrders}/${orderId}`)
            .then(response => response.json())
            .then(data => {
                setShopOrder(data);
                if(data.status === 'New' && !data.paymentLink) { setIsInconsistentStatuesModalVisible(true); }
            });
    }, [orderId]);

    const cancelOrder = async (paymentSessionId) => {
        setSubmitting(true);
        await fetch(ApiEndpoints.CancelPayment, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(paymentSessionId)
        });

        window.location.reload();
    }

    if (!shopOrder) {
        return <div></div>;
    }

    return (
         <div className="order-details">
            <NavMenuShop />
            <div className="container">
                <h1 align="center">Order Details</h1>
                 <div class="info-card">
                    <h2>General info</h2>
                    <hr/>
                    <p><strong>Date & Time: </strong>{formatDateToDisplay(new Date(shopOrder.creation))}</p>
                    <p><strong>Total cost: </strong><span className="notranslate">{getFixedNumberPrice(shopOrder.totalPrice)} €</span></p>
                    <p><strong>Status: </strong> <OrderStatusBadge status={shopOrder.status} /></p>
                    {
                        !!shopOrder.infoFromSeller && <div><strong>Info from seller: </strong><pre>{shopOrder.infoFromSeller}</pre></div>
                    }
                    {
                        shopOrder.paymentSessionId &&
                             <p>
                                <div className="row">
                                    <div className="col-md-3 m-auto">
                                        <strong>Actions: </strong>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row">
                                            {
                                                shopOrder.paymentLink &&
                                                    <div className="col-md-6 mb-2">
                                                        <a disabled={submitting} style={{ marginRight: 10 }} className="btn btn-primary w-100" href={shopOrder.paymentLink}>Pay the order</a>
                                                    </div>
                                            }
                                            <div className="col-md-6">
                                                <button disabled={submitting} class="btn btn-danger w-100" onClick={() => cancelOrder(shopOrder.paymentSessionId)}>Cancel order</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                    }
                  </div>
                  <div class="info-card mt-4">
                      <h2>Delivery info</h2>
                      <hr/>
                      <p><strong>Country: </strong>{shopOrder.country}</p>
                      <p><strong>Province: </strong>{shopOrder.province}</p>
                      <p><strong>City: </strong>{shopOrder.city}</p>
                      <p><strong>Postal / Zip code: </strong>{shopOrder.postalCode}</p>
                      <p><strong>Address line 1: </strong>{shopOrder.address1}</p>
                      <p><strong>Address line 2: </strong>{shopOrder.address2}</p>
                      <p><strong>Comments: </strong>{shopOrder.comments}</p>
                      <h4>Delivery methods</h4>
                      <hr/>
                      {
                          shopOrder.deliveries.map(delivery =>
                            <div class="card m-3 delivery-card">
                               <div class="card-header delivery-card-header d-flex flex-wrap justify-content-between">
                                   <span>
                                        {delivery.name}
                                    </span>
                                     <span>
                                        Cost: <span className="notranslate">{getFixedNumberPrice(delivery.price)} €</span>
                                     </span> 
                               </div>
                               <div class="card-body">
                                  {delivery.description}
                               </div>
                            </div>
                         )
                      }
                  </div>
                  <div class="info-card mt-4 mb-4">
                      <h2>Items</h2>
                        <table>
                          <thead>
                            <tr>
                              <th scope="col" width="100">Preview</th>
                              <th scope="col">Name</th>
                              <th scope="col">Price</th>
                              <th scope="col">Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                              {
                                  shopOrder.items.map(item =>
                                        <tr>
                                            <td data-label="Preview">
                                                <a className="text-decoration-none" href={`/shop/${item.itemId}`}>
                                                    <img className="order-item-preview" src={ApiEndpoints.PublicFile + '/' + item.thumbnailFileName} />
                                                </a>
                                            </td>
                                            <td data-label="Name">
                                                <a className="text-decoration-none text-dark" href={`/shop/${item.itemId}`}>{item.name}</a>
                                            </td>
                                            <td className="notranslate" data-label="Price">{getFixedNumberPrice(item.price)} €</td>
                                            <td data-label="Quantity">{item.quantity}</td>
                                        </tr>)
                              }
                          </tbody>
                        </table>
                  </div>

            </div>
            {
                isInconsistentStatuesModalVisible &&
                   <InconsistentStatuesModal setShowModal={setIsInconsistentStatuesModalVisible} />
            }
        </div>
    );
}
import * as Yup from 'yup';

export class ShopItemCreateEditValidation {
    constructor() {
        this.validationSchema = Yup.object().shape({
            name: Yup.string()
                .required('Please fill the field: Name')
                .max(512, 'Name cannot be more than 512 characters'),

            description: Yup.string()
                .required('Please fill the field: Description'),

            price: Yup.number()
                .typeError('Please fill the field: Price')
                .required('Please fill the field: Price')
                .min(0, 'Price cannot be less than 0'),

            stockQuantity: Yup.number()
                .typeError('Please fill the field: Stock Quantity')
                .required('Please fill the field: Stock Quantity')
                .min(0, 'Stock Quantity cannot be less than 0'),

            deliveries: Yup.array()
                .min(1, "Please select at least one delivery method")
                .required("Please select at least one delivery method"),

            categories: Yup.array()
                .min(1, "Please select at least one category")
                .required("Please select at least one category"),

            assets: Yup.array()
                .of(Yup.object().shape({
                    id: Yup.string(),
                    original: Yup.string(),
                }).test('at-least-one', 'Please check all asset original files', function(value) {
                    return value.id || value.original;
                }))
        });
    }

    async validate(item) {
        try {
            await this.validationSchema.validate(item, { abortEarly: false });
            return null;
        } catch (errors) {
            return errors.inner[0].message;
        }
    }
}

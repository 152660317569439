import { MaxItemsInCart } from '../cart/ShopCart';

export class CartHelper {
    static TryAddItemToCart(
        id,
        title,
        image,
        quantity,
        cart,
        setCurrentCartItem,
        setShopExceedsQuantity, 
        setCartLimitReached)
    {
            if (cart.getTotalCount() >= MaxItemsInCart) {
                setCartLimitReached(true);
                return;
            }

            if (cart.getItems()[id] && cart.getItems()[id] >= quantity) {
                setShopExceedsQuantity(true);
                return;
            }

            cart.addItem(id);
            setCurrentCartItem({ title, image });
    }
}
import React, { Fragment, useState, useEffect } from 'react';
import './shopDeliveriesList.css';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ShopDeliveryDeleteModal } from './ShopDeliveryDeleteModal';
import { ShopDeliveryCreateEditModal } from './ShopDeliveryCreateEditModal';

export function ShopDeliveriesList() {
    const [deliveries, setDeliveries] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentDelivery, setCurrentDelivery] = useState({ name: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDeliveryId, setDeleteDeliveryId] = useState(null);

    useEffect(() => {
        fetchDeliveries();
    }, []);

    const fetchDeliveries = async () => {
        const response = await fetch(ApiEndpoints.ManageShopDeliveries);
        const data = await response.json();
        setDeliveries(data);
    };

    const handleCreate = () => {
        setCurrentDelivery({ name: '' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleEdit = (delivery) => {
        setCurrentDelivery(delivery);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        setDeleteDeliveryId(id);
    };

    return (
        <Fragment>
            <NavMenuShop />
            <div className="container delivery-manager">
                <button className="btn btn-primary mb-3" onClick={handleCreate}>Create Delivery</button>
                 <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th width="16%">Name</th>
                            <th width="16%">Internal name</th>
                            <th width="100">Price</th>
                            <th>Countries</th>
                            <th width="20%">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveries.map((delivery, index) => (
                             <tr>
                                <td data-label="Name">{delivery.name}</td>
                                <td data-label="Internal name">{delivery.internalName}</td>
                                <td data-label="Price">{delivery.price}</td>
                                <td>
                                    <ul>
                                    {delivery.countries.map((country) => (
                                        <li>{country.name}</li>
                                    ))}
                                    </ul>
                                </td>
                                <td data-label="Actions">
                                    <button className="btn btn-warning mr-2" onClick={() => handleEdit(delivery)}>Edit</button>
                                    <button className="btn btn-danger" onClick={() => handleDelete(delivery.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {showModal && (
                    <ShopDeliveryCreateEditModal
                        currentDelivery={currentDelivery}
                        setCurrentDelivery={setCurrentDelivery}
                        setShowModal={setShowModal}
                        fetchDeliveries={fetchDeliveries}
                        isEditing={isEditing}
                    />
                )}
                {deleteDeliveryId && (
                    <ShopDeliveryDeleteModal
                        deleteDeliveryId={deleteDeliveryId}
                        setDeleteDeliveryId={setDeleteDeliveryId}
                        fetchDeliveries={fetchDeliveries}
                    />
                )}
            </div>
        </Fragment>
    );
}

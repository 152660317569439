import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import DatePicker from "react-datepicker";
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';

export function UserChangeEmailModal({userEmail, userId, setUserId, fetchUsers, setPage}) {
    const [submitting, setSubmitting] = useState(false);
    const [newEmail, setNewEmail] = useState(userEmail);
    const modalWindowRef = useRef();

    const confirmSubmit = async (e) => {
        if (submitting || !newEmail) return;
        setSubmitting(true);

        await fetch(`${ApiEndpoints.ChangeEmail}/${userId}?newEmail=${newEmail}`, { method: "GET" });
        setPage(0);
        await fetchUsers(0);
        modalWindowRef.current.closeWindow();
    }

    return (
        <ModalWindow
            ref={modalWindowRef}
            title="Change email for the user"
            onClose={() => setUserId(null)}
        >
            <p>Please specify new email for the user</p>
            <p className="text-danger">New Email automatically will be activated! Do it carefully, after ensuring that the User proofed their identity and is the owner of the new Email</p>
            <label>
                New Email
                <div className="required d-inline"><RequiredSvgIcon /></div>
            </label>
            <input type="text" className="form-control" required value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={confirmSubmit} disabled={submitting}>Confirm</button>
            </div>
        </ModalWindow>
    );
}
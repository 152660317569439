import React from 'react'
import './ShopItemPreview.css'
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import CartImg from '../../../../assets/shop/cart.png';
import { CartStorage } from '../../../../storage/cartStorage';
import { CartHelper } from '../cartHelper';
import { getFixedNumberPrice } from '../../../../functions/prices';

export function ShopItemPreview(props) {
    let bg_img = `url(${props.image})`;
    let { title, oldPrice, newPrice, id, setCurrentCartItem, quantity, setShopExceedsQuantity, setCartLimitReached } = props;
    const cart = new CartStorage();
    const addToCart = (e) => {
        e.preventDefault();
        CartHelper.TryAddItemToCart(
            id,
            title,
            props.image,
            quantity,
            cart,
            setCurrentCartItem,
            setShopExceedsQuantity,
            setCartLimitReached);
    }
    
    return (
        <div className="shop-item-preview">
            <div className="wrapper text-center">
                 <span hidden className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger pretty-font">
                    NEW
                 </span>
               <div className="d-flex justify-content-center">
                    <img src={props.image} className="card_img" />
                   </div>
                <div className="cardInfo">
                    <p className="pretty-font item-title">{title}</p>
                    <div className="action">
                        {
                            quantity > 0
                                ? (
                                <>
                                    <div className="priceGroup">
                                        {
                                            oldPrice === newPrice
                                                ? <p className="old-price-stub mb-0"></p>
                                                : <p className="pretty-font price old_price mb-0 text-danger notranslate">{getFixedNumberPrice(oldPrice)} €</p>
                                        }
                                        <p className="pretty-font price newPrice m-0 notranslate">{getFixedNumberPrice(newPrice)} €</p>
                                    </div>
                                    <div className="cart">
                                        <img src={CartImg} className="cart-img" onClick={addToCart} />
                                        {
                                            cart.getItems()[id] && cart.getItems()[id] > 0
                                                ?  <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-success pretty-font">
                                                    {cart.getItems()[id]}
                                                   </span>
                                                : null
                                        }
                                    </div>
                                </>)
                            : <p className="pretty-font text-danger out-of-stock">Out of stock</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
import * as Yup from 'yup';

export class ShopOrderValidation {
    constructor() {
        this.validationSchema = Yup.object().shape(
            {
                countryId: Yup.string()
                    .required('Please select a country'),
                items: Yup.array()
                    .of(Yup.object().shape({
                       quantity: Yup.number()
                           .typeError('Please check all items quantities. At least one item should be selected')
                           .required('Please check all items quantities. At least one item should be selected')
                           .min(1, 'Please check all items quantities. At least one item should be selected'),
                       
                       deliveryId: Yup.string()
                           .required('Please check that you have selected all delivery methods'),
                    }))
                   .min(1, 'Please check that you have selected at least one item')
                   .test(
                       'total-quantity',
                       'Total quantity of items must not exceed 20',
                       function (items) {
                           const totalQuantity = items.reduce((sum, item) => sum + item.quantity, 0);
                           return totalQuantity <= 20;
                       }
                   )
            });

        this.shippingDetailsValidationSchema = Yup.object().shape({
            address1: Yup.string()
                .required('Please fill the field: Address line 1')
                .max(100, 'Address line 1 cannot be more than 100 characters'),

            address2: Yup.string()
                .max(100, 'Address line 2 cannot be more than 100 characters'),

            city: Yup.string()
                .required('Please fill the field: City')
                .max(100, 'City cannot be more than 100 characters'),

            province: Yup.string()
                .required('Please fill the field: Province')
                .max(100, 'Province cannot be more than 100 characters'),

            postalCode: Yup.string()
                .required('Please fill the field: Postal / Zip code')
                .max(20, 'Postal / Zip code cannot be more than 20 characters')
        });
    }

    async validate(order, validateShippingDetails) {
        try {
            await this.validationSchema.validate(order, { abortEarly: false });
            if (validateShippingDetails) {
                await this.shippingDetailsValidationSchema.validate(order.shippingDetails, { abortEarly: false });
            }
            return null;
        } catch (errors) {
            return errors.inner[0].message;
        }
    }
}

import React, { useState, useRef } from 'react';
import { Form, FormGroup, Label, Input, Button, Col } from 'reactstrap';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import Select from 'react-select'
import { ShopItemAttachAsset } from './ShopItemAttachAsset';
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import { ModalValidation } from '../../../modal/validation/ModalValidation';
import { ShopItemCreateEditValidation } from './ShopItemCreateEditValidation';

export function ShopItemCreateEdit({ isEditing, setShowModal, categories, deliveries, item, fetchItems, handleDelete, setPage }) {
  const [shopItem, setShopItem] = useState(item);
  const [error, setError] = useState('');
  const validator = new ShopItemCreateEditValidation();
  const modalWindowRef = useRef();
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShopItem({ ...shopItem, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setShopItem({ ...shopItem, [name]: checked });
  };
  
  const handleCategoryChange = (selectedOptions) => {
      const selectedCategoryIds = selectedOptions?.map(option => ({ id: option.value }));
      setShopItem({ ...shopItem, categories: selectedCategoryIds });
  };
  
  const handleDeliveryChange = (selectedOptions) => {
        const selectedDeliveryIds = selectedOptions?.map(option => ({ id: option.value }));
        setShopItem({ ...shopItem, deliveries: selectedDeliveryIds });
  };

  const handleEditorChange = ({ html, text }) => {
      setShopItem({ ...shopItem, description: text });
  };

  const handleSave = async () => {
  if (submitting) return;
  setSubmitting(true);

  const validationError = await validator.validate(shopItem);
    if (validationError) {
      setError(validationError);
      document.getElementsByClassName("modal")[0].scrollTo(0, 0);
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    
    for (const key of Object.keys(shopItem)) {
      if (key !== 'assets' && key !== 'categories' && key !== 'deliveries') {
        formData.append(key, shopItem[key]);
      }
    }

    shopItem.categories && shopItem.categories.forEach((categoryItem, index) => {
        formData.append(`categories[${index}]`, categoryItem.id);
    });

    shopItem.deliveries && shopItem.deliveries.forEach((deliveryItem, index) => {
        formData.append(`deliveries[${index}]`, deliveryItem.id);
    });

    for (const [index, asset] of shopItem.assets.entries()) {
        formData.append(`assets[${index}].id`, asset.id);
        formData.append(`assets[${index}].description`, asset.description || '');
        formData.append(`assets[${index}].sorting`, asset.sorting);
        if (asset.thumbnail) {
            formData.append(`assets[${index}].thumbnail`, asset.thumbnail);
        }
        if (asset.original) {
            formData.append(`assets[${index}].original`, asset.original);
        }
    }

    try {
          await fetch(`${ApiEndpoints.ManageShopItems}/${isEditing ? item.id : ''}`,
          {
            method: isEditing ? 'put' : 'post',
            body: formData,
          });

          modalWindowRef.current.closeWindow();
          setPage(0);
          await fetchItems(0);
        } catch (error) {
          setSubmitting(false);
          console.error('Error submitting form:', error);
        }
  };
  
  const mdParser = new MarkdownIt();
  return (
    <ModalWindow
        ref={modalWindowRef}
        title={isEditing ? 'Edit Item' : 'Create Item'}
        onClose={() => setShowModal(false)}
    >
        <Form action="/api/management/ShopItems" method="POST">
          <ModalValidation error={error} />
          <FormGroup row>
            <Label for="name" sm={2}>
                Name
                <div className="required d-inline"><RequiredSvgIcon /></div>
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="name"
                id="name"
                value={shopItem.name}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="description" sm={2}>
                Description
                <div className="required d-inline"><RequiredSvgIcon /></div>
            </Label>
            <Col sm={10}>
                <MdEditor
                    onChange={handleEditorChange}
                    name="description"
                    style={{ height: '500px' }}
                    renderHTML={text => mdParser.render(text)}
                    value={shopItem.description}
                />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="price" sm={2}>
                Price
                <div className="required d-inline"><RequiredSvgIcon /></div>
            </Label>
            <Col sm={10}>
              <Input
                type="number"
                name="price"
                id="price"
                value={shopItem.price}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="stockQuantity" sm={2}>
                Stock Quantity
            </Label>
            <Col sm={10}>
              <Input
                type="number"
                name="stockQuantity"
                id="stockQuantity"
                value={shopItem.stockQuantity}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="archived" sm={2}>Archived</Label>
            <Col sm={10}>
              <Input
                type="checkbox"
                name="archived"
                id="archived"
                checked={shopItem.archived}
                onChange={handleCheckboxChange}
              />
            </Col>
          </FormGroup>
           <FormGroup row>
              <Label for="archived" sm={2}>
                  Categories
                  <div className="required d-inline"><RequiredSvgIcon /></div>
              </Label>
              <Col sm={10}>
                   <Select
                        options={categories}
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={shopItem.categories && shopItem.categories.map(x => ({ value: x.id, label: x.name }))}
                        onChange={handleCategoryChange}
                   />
              </Col>
            </FormGroup>
             <FormGroup row>
              <Label for="archived" sm={2}>
                  Deliveries
                  <div className="required d-inline"><RequiredSvgIcon /></div>
              </Label>
              <Col sm={10}>
                   <Select
                        options={deliveries}
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={shopItem.deliveries && shopItem.deliveries.map(x => ({
                            value: x.id,
                            label: x.name + (x.internalName ? ` (${x.internalName})` : '')
                        }))}
                        onChange={handleDeliveryChange}
                   />
              </Col>
            </FormGroup>
          <FormGroup row>
              {
                  shopItem.assets.map((asset, index) => (
                          <ShopItemAttachAsset
                              asset={asset}
                              onChange={(updatedAsset) => {
                                    const updatedAssets = [...shopItem.assets];
                                    updatedAssets[index] = updatedAsset;
                                    setShopItem({ ...shopItem, assets: updatedAssets });
                                }}
                                onRemove={() => {
                                    const updatedAssets = [...shopItem.assets];
                                    updatedAssets.splice(index, 1);
                                    setShopItem({ ...shopItem, assets: updatedAssets });
                                }}
                          />
                  ))
                }
             
          </FormGroup>
          <FormGroup row>
             <Col>
              <Button className="w-100 add-asset-btn" color="primary" onClick={() => setShopItem({ ...shopItem, assets: [...shopItem.assets, { sorting: 0 }] })}>
                   Add Asset
               </Button>
             </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={9}>
              <Button className="w-100 save-btn" color="primary" onClick={handleSave} disabled={submitting}>Save</Button>
            </Col>
             <Col sm={3}>
                <Button className="w-100 remove-asset-btn" color="primary" onClick={() => {modalWindowRef.current.closeWindow(); handleDelete(shopItem.id)}}>
                     Delete item
                 </Button>
             </Col>
          </FormGroup>
        </Form>
    </ModalWindow>
  );
}

import React, { useState, useEffect } from 'react';

import { ExpandSvgIcon } from '../../../assets/shop/ExpandSvgIcon';

const TreeNode = ({ node, onNodeClick, isExpanded, toggleExpand, disabled, level = 0 }) => {
  return (
    <div className="filter-category-node" style={{ marginLeft: level == 0 ? 0 : 10 }}>
      <div className={`filter-category-content list-group-item ${disabled ? 'disabled-category' : ''}`}>
          <span className="filter-category-name" onClick={
              disabled
                  ? null
                  : () => { onNodeClick(node); toggleExpand(node.id); }}
          >
            {node.name}
          </span>
        {node.children && node.children.length > 0 && (
          <span className="arrow" onClick={() => { toggleExpand(node.id) }}>
              <ExpandSvgIcon styles={{
                  height: 20,
                  width: 20,
                  fill: 'black',
                  transitionDuration: '0.2s',
                  transform: isExpanded(node.id) ? 'rotate(180deg)' : 'rotate(0deg)'
                }} />
          </span>
        )}
      </div>
      {isExpanded(node.id) && node.children && (
        <div>
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onNodeClick={onNodeClick}
              isExpanded={isExpanded}
              toggleExpand={toggleExpand}
              disabled={disabled}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export function TreeView({ data, onNodeClick, initialCategory, disabled }) {
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  
  const getInitiallyExpandedNodes = (categories, currentCategory) => {
    let expandedNodes = [];
    let findCategory = (nodes, target) => {
      for (let node of nodes) {
        if (node.id === target.id) {
          expandedNodes.push(node.id);
          return true;
        }
        if (node.children && findCategory(node.children, target)) {
          expandedNodes.push(node.id);
          return true;
        }
      }
      return false;
    };
    findCategory(categories, currentCategory);
    return expandedNodes;
  };
  
  useEffect(() => {
    if (initialCategory) {
        const initiallyExpandedNodes = getInitiallyExpandedNodes(data, initialCategory);
        setExpandedNodes(new Set(initiallyExpandedNodes));
    }
  }, [initialCategory]);

  const toggleExpand = (id) => {
    setExpandedNodes((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const isNodeExpanded = (id) => { return expandedNodes.has(id); } 
  return (
    <div className="tree-view">
      {data.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          onNodeClick={onNodeClick}
          isExpanded={isNodeExpanded}
          toggleExpand={toggleExpand}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
import React from 'react';
import './Fireworks.css';

export const Fireworks = ({ className }) => {
    return (
        <div>
                <div className={"firework " + className}></div>
                <div className={"firework " + className}></div>
                <div className={"firework " + className}></div>
        </div>
    );
}
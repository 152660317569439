import React, { useEffect, useState } from 'react';

export function ClickOnRenderedImage({ targetSrc }) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const clickImage = () => {
      const imgElement = document.querySelector(`img[src*='${targetSrc}']`);
      if (imgElement && !clicked) {
        imgElement.click();
        setClicked(true);
      }
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          clickImage();
        }
      });
    });

    const config = { childList: true, subtree: true, attributes: true };
    observer.observe(document.body, config);

    // Initial check in case the image is already rendered
    clickImage();

    return () => observer.disconnect();
  }, [targetSrc, clicked]);

  return null; // This component does not render anything
}

export default ClickOnRenderedImage;
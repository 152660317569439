import * as Yup from 'yup';

export class ShopDiscountCreateEditValidation {
    constructor() {
        this.validationSchema = Yup.object().shape({
            name: Yup.string()
                .required('Please fill the field: Name')
                .max(100, 'Name cannot be more than 100 characters'),

            startDate: Yup.date()
                .notRequired()
                .nullable(),

            endDate: Yup.date()
                .notRequired()
                .nullable()
                .min(
                    Yup.ref('startDate'),
                    'End Date cannot be before Start Date'
                ),

            percent: Yup.number()
                .typeError('Please fill the field: Percent')
                .required('Please fill the field: Percent')
                .min(0, 'Percent cannot be less than 0')
                .max(100, 'Percent cannot be more than 100'),

            active: Yup.boolean()
                .notRequired()
                .nullable(),

            usageLimit: Yup.number()
                .nullable()
                .min(1, 'Usage Limit must be at least 1')
                .notRequired(),

            promoCode: Yup.string()
                .notRequired()
                .nullable()
                .max(100, 'Promo Code cannot be more than 100 characters'),

            stackable: Yup.boolean()
                .notRequired()
                .nullable()
        });
    }

    async validate(discount) {
        try {
            await this.validationSchema.validate(discount, { abortEarly: false });
            return null;
        } catch (errors) {
            return errors.inner[0].message;
        }
    }
}

import React from 'react';
import { FormGroup, Label, Input, Button, Col } from 'reactstrap';
import ImageUploading from 'react-images-uploading';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';

export const ShopItemAttachAsset = ({ asset, onChange, onRemove }) => {
  const handleAssetChange = (imageList, index, type) => {
    const updatedAsset = { ...asset };
    updatedAsset[type] = imageList[0]?.file;
    updatedAsset[`${type}_url`] = imageList[0]?.data_url;
    onChange(updatedAsset);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedAsset = { ...asset, [name]: value };
    onChange(updatedAsset);
  };

  return (
    <div className="mb-3 shop-item-asset-container">
      <h4>Asset</h4>
      <FormGroup row>
        <Label for={`description${asset.id}`} sm={2}>Description</Label>
        <Col sm={10}>
          <Input
            type="text"
            name="description"
            id={`description${asset.id}`}
            value={asset.description || ''}
            onChange={handleInputChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for={`sorting${asset.id}`} sm={2}>Sorting</Label>
        <Col sm={10}>
          <Input
            type="number"
            name="sorting"
            id={`sorting${asset.id}`}
            value={asset.sorting || 0}
            onChange={handleInputChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="m-auto" sm={2}>Thumbnail</Label>
        <Col sm={10}>
            {
                asset.id ? (<img src={ApiEndpoints.PublicFile + "/" + asset.thumbnailFileName} alt="" height="100" />)
                : (
                      <ImageUploading
                        value={asset.thumbnail ? [{ data_url: asset.thumbnail_url }] : []}
                        onChange={(imageList) => handleAssetChange(imageList, 0, 'thumbnail')}
                        dataURLKey="data_url"
                        acceptType={["jpg", "jpeg", "png"]}
                        name="thumbnails[]"
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div className="upload__image-wrapper d-flex">
                            <Button className="upload-image-btn" color="primary" onClick={onImageUpload}>
                              Upload Thumbnail
                            </Button>
                            &nbsp;
                            {imageList.length > 0 && (
                              <div>
                                <img className="upload-image-preview" src={imageList[0].data_url} alt="" width="100" />
                                <Button color="danger" onClick={() => onImageRemove(0)}>Remove</Button>
                              </div>
                            )}
                          </div>
                        )}
                      </ImageUploading>
                  )
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="m-auto" sm={2}>
            Original
            <div className="required d-inline"><RequiredSvgIcon /></div>
        </Label>
        <Col sm={10}>
            {
                asset.id ? (<img src={ApiEndpoints.PublicFile + "/" + asset.originalFileName} alt="" height="100" />)
                : (
                  <ImageUploading
                      value={asset.original ? [{ data_url: asset.original_url }] : []}
                      onChange={(imageList) => handleAssetChange(imageList, 0, 'original')}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png"]}
                      name="originals[]"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="upload__image-wrapper d-flex">
                          <Button color="primary" className="upload-image-btn" onClick={onImageUpload}>
                            Upload Original
                          </Button>
                          &nbsp;
                          {imageList.length > 0 && (
                            <div>
                              <img className="upload-image-preview" src={imageList[0].data_url} alt="" width="100" />
                              <Button color="danger" onClick={() => onImageRemove(0)}>Remove</Button>
                            </div>
                          )}
                        </div>
                      )}
                    </ImageUploading>  
                )
            }
        </Col>
      </FormGroup>
      <Button color="danger remove-asset-btn" onClick={onRemove}>Remove Asset</Button>
    </div>
  );
};
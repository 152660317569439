import React, { useEffect, useState, Fragment } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import './ShopItemDetails.css';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { buildTreeData } from '../../../../functions/categoryTrees';
import { CartStorage } from '../../../../storage/cartStorage';
import { CartHelper } from '../cartHelper';
import { ShopCartAddedModal } from '../../cart/cartAddedModal/ShopCartAddedModal';
import { ShopCartExceedsStockQuantity } from '../../cart/cartExceedsStockQuantity/ShopCartExceedsStockQuantity';
import { ShopCartExceedsLimit } from '../../cart/cartExceedsLimit/ShopCartExceedsLimit';
import { MaxItemsInCart } from '../../cart/ShopCart'
import { getFixedNumberPrice } from '../../../../functions/prices';

export const ShopItemDetails = ({ itemId }) => {
    const [shopItem, setShopItem] = useState(null);
    const [currentCartItem, setCurrentCartItem] = useState(null);
    const [shopExceedsQuantityModal, setShopExceedsQuantityModal] = useState(false);
    const [shopCartLimitReachedModal, setShopCartLimitReachedModal] = useState(false);
    const cart = new CartStorage();
    
    const addToCart = (e) => {
        e.preventDefault();
        CartHelper.TryAddItemToCart(
            shopItem.id,
            shopItem.name,
            `${ApiEndpoints.PublicFile}/${shopItem.assets[0]?.thumbnailFileName}`,
            shopItem.stockQuantity,
            cart,
            setCurrentCartItem,
            setShopExceedsQuantityModal,
            setShopCartLimitReachedModal);
    }

    useEffect(() => {
        fetch(`${ApiEndpoints.ShopItems}/${itemId}`)
            .then(response => response.json())
            .then(data => {
                document.title = data.name + ' | Anna Shepeta';
                setShopItem(data);
            });
    }, [itemId]);

    if (!shopItem) {
        return <div></div>;
    }

    const dfsCategoriesTraversal = (categories) => {
        if (!categories || categories.length === 0) return [];
    
        let result = [];
        let maxDepth = -1;
        const stack = [{ categoryPath: [categories[0]], level: 0 }];
    
        while (stack.length > 0) {
            const { categoryPath, level } = stack.pop();
            const category = categoryPath[level];
    
            if (category.children && category.children.length > 0) {
                stack.push({ categoryPath: categoryPath.concat(category.children[0]), level: level + 1 });
            } else if (level > maxDepth) {
                maxDepth = level;
                result = categoryPath;
            }
        }
    
        return result;
    };

    const mdParser = new MarkdownIt();
    return (
        <span>
            <NavMenuShop />
            <Container className="shop-item-details">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/shop">All items</a></li>
                      {
                          shopItem.categories && dfsCategoriesTraversal(buildTreeData(shopItem.categories))
                                .map(category => (
                                    <li className="breadcrumb-item"><a href={`/shop?category=${category.id}`}>{category.name}</a></li>
                                ))
                      }
                  </ol>
                </nav>
                <Row className="my-5 flex-row-reverse">
                    <Col md="4">
                        <div>
                            <h2>
                                {shopItem.name} { shopItem.archived ? <span className="text-danger">(Archived)</span> : null }
                            </h2>
                            <MdEditor
                                renderHTML={text => mdParser.render(shopItem.description)}
                                value={shopItem.description}
                                readOnly={true}
                                view={{ html: true, menu: false, md: false }}
                            />  
                            { shopItem.archived || shopItem.stockQuantity <= 0
                                ? <h3 className="text-danger mt-4">Out of stock</h3>
                                : <Fragment>
                                      <p className="mt-4 notranslate">
                                          {
                                              shopItem.price === shopItem.discountedPrice
                                                  ? null
                                                  : <h5 className="d-inline text-danger old-price notranslate">
                                                      <s>{getFixedNumberPrice(shopItem.price)} €</s>
                                                    </h5>
                                          }
                                          <h3 className="d-inline notranslate">
                                              {getFixedNumberPrice(shopItem.discountedPrice)} €
                                          </h3>
                                      </p>
                                      <div className="mt-4">
                                          <label htmlFor="quantity" className="form-label">
                                              <b>Quantity:</b> <br/>
                                               {shopItem.stockQuantity} left in stock <br/>
                                               {cart.getItems()[shopItem.id] ? `${cart.getItems()[shopItem.id]} added in cart` : ''}
                                          </label>
                                          <Input
                                                hidden
                                                type="number"
                                                id="quantity"
                                                min="1"
                                                max={Math.min(MaxItemsInCart, cart.getItems()[shopItem.id]
                                                        ? Math.max(1, shopItem.stockQuantity - cart.getItems()[shopItem.id])
                                                        : shopItem.stockQuantity)}
                                                defaultValue="1"
                                                className="mb-3" />
                                          <Button color="primary" className="w-100 add-cart-btn" onClick={addToCart}>Add to Cart</Button>
                                      </div>
                                  </Fragment>
                            }
                        </div>
                    </Col>
                    <Col md="8">
                        {shopItem.assets.map(asset => (
                            <img
                                src={`${ApiEndpoints.PublicFile}/${asset.originalFileName}`}
                                className="img-fluid original-image"
                            />))}
                    </Col>
                </Row>
            </Container>
            {
                currentCartItem && <ShopCartAddedModal item={currentCartItem} setItem={setCurrentCartItem} />
            }
            {
                shopExceedsQuantityModal && <ShopCartExceedsStockQuantity setShopExceedsQuantity={setShopExceedsQuantityModal} />
            }
            {
                shopCartLimitReachedModal && <ShopCartExceedsLimit setShopLimitReached={setShopCartLimitReachedModal} />
            }
        </span>
    );
};

export default ShopItemDetails;
import React, { Component, Fragment, useState, useEffect } from 'react';
import { NavMenuShop } from '../../navMenu/NavMenuShop';
import './shopManagementDashboard.css'

import ShopCategoriesBtn from '../../../assets/shop/management/shop_categories.png';

export function ShopManagementDashboard() {
    return (
        <div className="shop-management-dashboard">
            <div className="container">
                <NavMenuShop />
                <h1 className="shop-management-title">Order management</h1>
                <div className="buttons row">  
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/orders">
                          <button className="dashboard-btn orders-btn">
                            <span>Orders</span>
                          </button>
                      </a>
                    </div>
                    <div className="col-md-3 col-6">
                       <a href="https://dashboard.stripe.com/">
                          <button className="dashboard-btn seller-dashboard-btn">
                            <span>Stripe<br/>Dashboard</span>
                          </button>
                       </a>
                    </div>
                </div>
                <h1 className="external-resources-title">Analytics</h1>
                <div className="buttons row">  
                    <div className="col-md-3 col-6">
                        <a href="http://win6112.site4now.net/stats/awstats.pl?config=1117127800&h=ba7adfe522b1204c4751e788cde10d2d">
                          <button className="dashboard-btn analytics-btn">
                            <span>Visitor<br/>stats</span>
                          </button>
                        </a>
                    </div>
                </div>
                <h1 className="shop-management-title">Shop management</h1>
                <div className="buttons row">  
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/items">
                          <button className="dashboard-btn items-btn">
                            <span>Items</span>
                          </button>
                      </a>
                    </div>
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/categories">
                          <button className="dashboard-btn categories-btn col-md-3 col-sm-5">
                            <span>Categories</span>
                          </button>
                      </a>
                    </div>
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/discounts">
                          <button className="dashboard-btn discounts-btn col-md-3 col-sm-5">
                            <span>Discounts</span>
                          </button>
                      </a>
                    </div>
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/deliveries">
                          <button className="dashboard-btn deliveries-btn col-md-3 col-sm-5">
                            <span>Deliveries</span>
                          </button>
                      </a>
                    </div>
                </div>
                <h1 className="user-management-title">User management</h1>
                <div className="buttons row">  
                    <div className="col-md-3 col-6">
                      <a href="/shop/management/users">
                          <button className="dashboard-btn users-btn">
                            <span>Users</span>
                          </button>
                      </a>
                    </div>
                </div>
                <h1 className="mail-management-title">Mail management</h1>
                <div className="buttons row">  
                    <div className="col-md-3 col-6">
                      <button className="dashboard-btn mail-templates-btn">
                        <span>Mail<br/>templates</span>
                      </button>
                    </div>
                    <div className="col-md-3 col-6">
                      <button className="dashboard-btn mail-config-btn">
                        <span>Mail<br/>config</span>
                      </button>
                    </div>
                    <div className="col-md-3 col-6">
                      <button className="dashboard-btn send-newsletter-btn">
                        <span>Send<br/>newsletter</span>
                      </button>
                    </div>
                </div>
            </div>
        </div>
    )
}  
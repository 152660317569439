import React, { useState } from 'react';
import { NavMenuShop } from '../../navMenu/NavMenuShop';
import './ShopFaq.css';
import { PrivacyPolicyModal } from './privacyPolicy/PrivacyPolicyModal';

export function ShopFaq() {
    document.title = 'FAQ | Anna Shepeta';
    var [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    return (
        <div className="faq">
            <NavMenuShop />
             <div class="container my-5">
               <header class="text-center mb-5">
                 <h1 class="display-6">Frequently Asked Questions</h1>
               </header>
               <section>
                   <div class="card mb-4">
                     <h5 class="card-header">🌜WHAT SHIPPING OPTIONS DO YOU OFFER?🌛</h5>
                     <div class="card-body">
                       <p class="card-text">
                           <p>I offer four different shipping methods, two international and two domestic (within Poland).</p>
                            <ul>
                              <li><strong>🍄 International tracked mail:</strong> With this shipping option your order is packaged in a cardboard backed or poly mailer and tracked so you can follow its journey.</li>
                              <li><strong>🍄 International untracked mail:</strong> With this shipping option your order is packaged in a cardboard backed or poly mailer but it will not be trackable and may take longer to arrive.</li>
                              <li><strong>🍄‍🟫 Polish tracked mail:</strong> With this shipping option your order is packaged in a cardboard backed or poly mailer and tracked so you can follow its journey within Poland.</li>
                              <li><strong>🍄‍🟫 Polish untracked mail:</strong> With this shipping option your order is packaged in a cardboard backed or poly mailer but it will not be trackable and may take longer to arrive.</li>
                            </ul>
                            <p className="mb-0">I can ship to most countries except Russia, Sudan and North Korea. If your country is not on the list when selecting shipping, please contact me.</p>
                       </p>
                     </div>
                   </div>
                   
                   <div class="card mb-4">
                    <h5 class="card-header">🌜WHEN WILL MY ORDER SHIP?🌛</h5>
                    <div class="card-body">
                      <p class="card-text">
                          <p>Please allow 3-5 business days for your order to ship. Once shipped you will receive an email.</p>
                          <p>These are the following expected delivery times:</p>
                           <ul>
                             <li>For domestic Polish orders: 1-2 week delivery</li>
                             <li>For international orders: 2-4 weeks delivery</li>
                           </ul>
                           <p className="mb-0">Be aware that these are only estimates, international shipping may take longer. If your order hasn’t arrived six weeks after your shipping confirmation email, please contact me and we’ll sort it out ✨</p>
                      </p>
                    </div>
                  </div>
                   
                   <div class="card mb-4">
                    <h5 class="card-header">🌜DO YOU OFFER RETURNS OR EXCHANGES?🌛</h5>
                    <div class="card-body">
                      <p class="card-text">
                          <p>I do not offer returns or exchanges. However, if there is an issue with a product or something is missing, contact me and we’ll work it out!</p>
                           <p className="mb-0">Also, make sure you’ve read up on your countries customs. In some cases you may have to pay import taxes otherwise your order will be returned to me. In that case I will refund your order but I will not have the possibility to refund the shipping costs.</p>
                      </p>
                    </div>
                  </div>
                 
               </section>
               <button class="btn btn-primary w-100" type="button" onClick={() => setShowPrivacyPolicy(true)}>
                 Privacy Policy
               </button>
             </div>
             {
                 showPrivacyPolicy && <div class="modal-backdrop fade show"></div>
             }
             {
                 showPrivacyPolicy && <PrivacyPolicyModal setShowModal={setShowPrivacyPolicy} />
             }
        </div>
    );
}
import React from 'react';
import { Component, useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';
import { useLocation } from 'react-router-dom';
import { ApiEndpoints } from '../../api/apiEndpoints';
import { CartStorage } from '../../storage/cartStorage';
import authService from '../api-authorization/AuthorizeService';

export function NavMenuShop(props) {

  const cart = new CartStorage();
  const getPublicFileUrl = (fileName) => ApiEndpoints.PublicFile + "/zz_nav_" + fileName;
  const handleMouseOver = (e, hoverImage) => { e.currentTarget.src = hoverImage; }
  const handleMouseOut = (e, normalImage) => { e.currentTarget.src = normalImage; }

  const [state, setState] = useState({ collapsed: true });
  const [isAdmin, setIsAdmin] = useState(false);
  const toggleNavbar = () => { setState({ collapsed: !state.collapsed }); }
  
  useEffect(async () => {
    const user = await authService.getUser();
    if (user) {
      const roles = user.role;
      if (roles?.includes('Admin')) {
        setIsAdmin(true);
      }
    }
  }, []);

  var location = useLocation();
  return (
      <header>
        <Navbar className="navbar-expand-md navbar-toggleable-md ng-white box-shadow mb-3" light>
          <Container>
            <NavbarBrand href="/">
                <img src={getPublicFileUrl('logo.png')} className="logo" alt="Logo" />
            </NavbarBrand>
            <NavbarToggler style={{backgroundImage:`url(${getPublicFileUrl('nav-toggle.png')})`}} onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!state.collapsed} navbar>
              <ul className="navbar-nav flex-wrap">
                <NavItem>
                  <NavLink className="text-dark" href="/shop">
                    <span className={'link-title shop-nav-title ' + (location.pathname === '/shop' ? 'text-decoration-underline' : '')}>Shop</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/cart">
                    <span className={'position-relative link-title cart-nav-title ' + (location.pathname === '/cart' ? 'text-decoration-underline' : '')}>
                    Cart
                    {
                        cart.getTotalCount() <= 0 
                        ? null
                        : <span className="position-absolute translate-middle badge rounded-pill bg-success pretty-font">
                             {cart.getTotalCount()}
                          </span>
                     }
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/profile">
                       <span className={'link-title profile-nav-title ' + (location.pathname === '/profile' ? 'text-decoration-underline' : '')}>Your profile</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/faq">
                       <span className={'link-title faq-nav-title ' + (location.pathname === '/faq' ? 'text-decoration-underline' : '')}>FAQ</span>
                  </NavLink>
                </NavItem>
                {
                    isAdmin &&
                        <NavItem>
                          <NavLink className="text-dark" href="/shop/management/main">
                               <span className={'link-title admin-nav-title ' + (location.pathname === '/shop/management/main' ? 'text-decoration-underline' : '')}>Admin</span>
                          </NavLink>
                        </NavItem>
                }
                <NavItem className="d-flex align-items-center">
                  <a className="text-dark nav-link" href="mailto:contact@annashepeta.art">
                      <img src={getPublicFileUrl('contact.png')} alt="Contact" className="img-fluid" onMouseOver={(e) => handleMouseOver(e, getPublicFileUrl('contact_selected.png'))} onMouseOut={(e) => handleMouseOut(e, getPublicFileUrl('contact.png'))} />
                  </a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
}

import React, { useRef, useState } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from 'react-select/async';
import { RequiredSvgIcon } from '../../../../assets/shop/RequiredSvgIcon';
import { ModalWindow } from '../../../modal/ModalWindow';
import { ModalValidation } from '../../../modal/validation/ModalValidation';
import { ShopDiscountCreateEditValidation } from './ShopDiscountCreateEditValidation';

export function ShopDiscountCreateEditModal({currentDiscount, setCurrentDiscount, setShowModal, fetchDiscounts, isEditing}) {
  const modalWindowRef = useRef();
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const validator = new ShopDiscountCreateEditValidation();

  const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) return;
        setSubmitting(true);

        const validationError = await validator.validate(currentDiscount);
        if (validationError) {
            setError(validationError);
            document.getElementsByClassName("modal")[0].scrollTo(0, 0);
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        for (const key of Object.keys(currentDiscount)) {
            if (!['users', 'shopItems', 'shopItemCategories', 'startDate', 'endDate', 'promoCode' ].includes(key)) {
                formData.append(key, currentDiscount[key]);
            } else if (key == 'promoCode') {
                if (currentDiscount[key]) { formData.append(key, currentDiscount[key]); }
            }
            else if (key === 'startDate' || key === 'endDate') {
                formData.append(key, new Date(currentDiscount[key]).toDateString());
            } else {
                currentDiscount[key] && currentDiscount[key].forEach((selectItem, index) => {
                    formData.append(`${key}[${index}]`, selectItem.id);
                });
            }
        }
    
        await fetch(ApiEndpoints.ManageShopDiscounts + (isEditing ? `/${currentDiscount.id}` : ''), {
            method: isEditing ? "PUT" : "POST",
            body: formData,
        });
    
        fetchDiscounts();
        modalWindowRef.current.closeWindow();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentDiscount(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

  const handleDateChange = (name, value) => {
      setCurrentDiscount(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleSelectChange = (name, selectedOptions) => {
    const selectedValues = selectedOptions?.map(option => ({ id: option.value }));
    setCurrentDiscount(prevState => ({ ...prevState, [name]: selectedValues }));
  };
  
  const handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      setCurrentDiscount(prevState => ({ ...prevState, [name]: checked }));
  };
  
  const suggestUsers = async (filter) => {
        const response = await fetch(ApiEndpoints.SuggestUsersForDiscounts + `?filter=${filter}`);
        const data = await response.json();
        return data.map((user) => ({ value: user.id, label: getUserLabel(user) }));
  };

   const getUserLabel = (user) => `${user.firstName} ${user.lastName} (${user.email})`;

    const suggestItems = async (filter) => {
        const response = await fetch(ApiEndpoints.SuggestShopItemsForDiscounts + `?filter=${filter}`);
        const data = await response.json();
        return data.map((item) => ({ value: item.id, label: item.name }));
    };

    const suggestItemCategories = async (filter) => {
        const response = await fetch(ApiEndpoints.SuggestShopItemCategoriesForDiscounts + `?filter=${filter}`);
        const data = await response.json();
        return data.map((item) => ({ value: item.id, label: item.name }));
    };

   return (
       <ModalWindow
            ref={modalWindowRef}
            title={isEditing ? 'Edit Discount' : 'Create Discount'}
            onClose={() => setShowModal(false)}>
               <form onSubmit={handleSubmit}>
                 <ModalValidation error={error} />
                 <div className="form-group">
                   <label htmlFor="name">
                       Name
                       <div className="required d-inline"><RequiredSvgIcon /></div>
                   </label>
                   <input
                     type="text"
                     className="form-control"
                     id="name"
                     name="name"
                     value={currentDiscount.name}
                     onChange={handleInputChange}
                   />
                 </div>
                 <div className="form-group">
                    <label htmlFor="startDate">Start Date</label>
                    <DatePicker
                        id="startDate"
                        selected={currentDiscount.startDate && new Date(currentDiscount.startDate)}
                        name="startDate"
                        className="form-control"
                        dateFormat="dd.MM.yyyy"
                        onChange={(date) => handleDateChange('startDate', date)}
                    />
                  </div>
                  <div className="form-group">
                      <label htmlFor="endDate">End Date</label>
                      <DatePicker
                          id="endDate"
                          selected={currentDiscount.endDate && new Date(currentDiscount.endDate)}
                          name="endDate"
                          className="form-control"
                          dateFormat="dd.MM.yyyy"
                          onChange={(date) => handleDateChange('endDate', date)}
                      />
                  </div>
                  <div className="form-group">
                     <label htmlFor="percent">
                         Percent
                         <div className="required d-inline"><RequiredSvgIcon /></div>
                     </label>
                     <input
                       type="number"
                       className="form-control"
                       id="percent"
                       name="percent"
                       value={currentDiscount.percent}
                       onChange={handleInputChange}
                     />
                  </div>
                  <div className="form-group">
                   <label htmlFor="active" className="checkbox-label">Active</label>
                   <input
                     type="checkbox"
                     className="form-check-input"
                     id="active"
                     name="active"
                     checked={currentDiscount.active}
                     value={currentDiscount.active}
                     onChange={handleCheckboxChange}
                   />
                 </div>
                 <div className="form-group">
                      <label htmlFor="usageLimit">Usage Limit (for promo code)</label>
                      <input
                        type="number"
                        className="form-control"
                        id="usageLimit"
                        name="usageLimit"
                        value={currentDiscount.usageLimit}
                        onChange={handleInputChange}
                      />
                 </div>
                 <div className="form-group">
                      <label htmlFor="promoCode">Promo Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="promoCode"
                        name="promoCode"
                        value={currentDiscount.promoCode}
                        onChange={handleInputChange}
                      />
                 </div>
                 <div className="form-group">
                    <label htmlFor="stackable" className="checkbox-label">Stackable</label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="stackable"
                      name="stackable"
                      value={currentDiscount.stackable}
                      checked={currentDiscount.stackable}
                      onChange={handleCheckboxChange}
                    />
                 </div>
                 <div className="form-group">
                     <label htmlFor="users" className="checkbox-label">Users</label>
                     <AsyncSelect
                        name="users"
                        id="users"
                        loadOptions={suggestUsers}
                        isMulti
                        onChange={(selectedOptions) => handleSelectChange('users', selectedOptions)}
                        defaultValue={currentDiscount.users?.map(user => ({ value: user.id, label: getUserLabel(user) }))}
                     />
                 </div>
                 <div className="form-group">
                      <label htmlFor="shopItems" className="checkbox-label">Shop Items</label>
                      <AsyncSelect
                        name="shopItems"
                        id="shopItems"
                        loadOptions={suggestItems}
                        isMulti
                        onChange={(selectedOptions) => handleSelectChange('shopItems', selectedOptions)}
                        defaultValue={currentDiscount.shopItems?.map(item => ({ value: item.id, label: item.name }))}
                      />
                 </div>
                 <div className="form-group">
                       <label htmlFor="shopItemCategories" className="checkbox-label">Shop Item Categories</label>
                       <AsyncSelect
                            name="shopItemCategories"
                            id="shopItemCategories"
                            loadOptions={suggestItemCategories}
                            isMulti
                            onChange={(selectedOptions) => handleSelectChange('shopItemCategories', selectedOptions)}
                            defaultValue={currentDiscount.shopItemCategories?.map(item => ({ value: item.id, label: item.name }))}
                       />
                 </div>
                 <button type="submit" className="btn btn-primary" disabled={submitting}>
                   {isEditing ? 'Save Changes' : 'Create Discount'}
                 </button>
           </form>
       </ModalWindow>
    );
 }
import './ModalWindow.css';
import { forwardRef, useRef, useImperativeHandle } from 'react';

export const ModalWindow = forwardRef(({ title, children, onClose }, ref) => {

  const modalContentRef = useRef(null);
  const modalClose = (event) => {
     modalContentRef.current.classList.add('fadeOut');
     setTimeout(() => {
        onClose();
     }, 500);
  };
  
  useImperativeHandle(ref, () => ({
      closeWindow() {
        modalClose();
      }
    }));
    
  return (
    <div className="modal modal-window" style={{ display: 'block' }}>
    <div className="modal-dialog">
        <div className="modal-content" ref={modalContentRef}>
            <div className="modal-header d-flex justify-content-between">
                 <h5 className="modal-title">{title}</h5>
                 <button type="button" className="close" onClick={modalClose}>
                    <span>&times;</span>
                 </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
        </div>
      </div>
    </div>
  );
});
import React from 'react';
import { CautionSvgIcon } from '../../../assets/shop/CautionSvgIcon';
import './ModalValidation.css';

export function ModalValidation({ error }) {
    return (
        error ? (
           <div class="alert alert-danger d-flex align-items-center modal-validation" role="alert">
               <div>
                   <CautionSvgIcon />
                   <span className="align-middle">{error}</span>
               </div>
           </div>
        ) : <></>
    );    
}
import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';

export function UserImpersonateModal({userId, setUserId, fetchUsers, setPage}) {
    const [submitting, setSubmitting] = useState(false);
    const modalWindowRef = useRef();

    const confirmSubmit = async (e) => {
        if (submitting) return;
        setSubmitting(true);

        await fetch(`${ApiEndpoints.Impersonate}/${userId}`, { method: "GET" });
        window.location.href = "/profile?refreshState=true";
    }

    return (
        <ModalWindow
            ref={modalWindowRef}
            title="Confirm impersonation"
            onClose={() => setUserId(null)}
        >
            <p>Are you sure you want to impersonate the user?</p>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={confirmSubmit} disabled={submitting}>Confirm</button>
            </div>
        </ModalWindow>
    );
}
import React, { Component, Fragment, useState, useEffect } from 'react';
import Lightbox from "react-image-lightbox";
import SpinnerImg from '../../assets/spinner.gif';

export function MediaGalleryLightBox ({ images, index, setIndex }) {
    
    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;
    
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => {
        setIndex(prevIndex);
    }

    const handleMoveNext = () => {
        setIndex(nextIndex);
    }

    const Spinner = () => (
      <div className="spinner">
        <img src={SpinnerImg} alt="Loading" />
        <p>Loading...</p>
      </div>
    );

    return (
        <div>
            {!!currentImage && (
                    <Lightbox
                      mainSrc={currentImage.original}
                      imageTitle={currentImage.caption}
                      mainSrcThumbnail={currentImage.original}
                      nextSrc={nextImage.original}
                      nextSrcThumbnail={nextImage.original}
                      prevSrc={prevImage.original}
                      prevSrcThumbnail={prevImage.original}
                      onCloseRequest={handleClose}
                      onMovePrevRequest={handleMovePrev}
                      onMoveNextRequest={handleMoveNext}
                      loader={<Spinner />}
                    />
                  )}
        </div>);
    }
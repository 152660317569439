export class ShippingCountryStorage {
    constructor() {
        this.storageKey = 'shipCountry';
        this.shipCountry = this._getCountryFromStorage();
    }

    _getCountryFromStorage() { return localStorage.getItem(this.storageKey); }
    _saveCountryToStorage() { localStorage.setItem(this.storageKey, this.shipCountry); }

    setCountry(countryId, countryName) {
         this.shipCountry = JSON.stringify({ id: countryId, name: countryName });
         this._saveCountryToStorage();
    }

    clearCountry() {
        this.shipCountry = null;
        this._saveCountryToStorage();
    }

    getCountry() { return this.shipCountry && JSON.parse(this.shipCountry); }
}

import React, { Component, Fragment, useRef } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import './ShopCartItemsMissing.css';

export function ShopCartItemsMissing({ setShowModal }) {
    const modalWindowRef = useRef();

    return (
        <div className="shop-cart-exceeds-quantity">
            <ModalWindow
                ref={modalWindowRef}
                title="Some items are missing"
                onClose={() => { setShowModal(false); }}
            >
                <p>Some items are missing from your cart</p>
                <p>That means that the stock quantity of some items has changed since you added them to your cart or they have been removed from the shop</p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Okay</button>
                </div>
            </ModalWindow>
        </div>
    );
}
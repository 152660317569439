import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { getStatuses } from './shopOrdersFunctions';

export function ShopOrderFilters({ filters, setFilters, setPage, fetchOrders }) {
    const [countries, setCountries] = useState([]);
    const statuses = getStatuses();

    useEffect(async () => {
        const countries = await fetch(ApiEndpoints.ShippingCountries);
        const data = await countries.json();
        setCountries(data);
    }, []);

    const onFilterChange = (value, key) => {
        var newFiltersState = {
            ...filters,
            [key]: value?.value
        };

        setFilters(newFiltersState);
        setPage(0);
        fetchOrders(0, false, newFiltersState);
    }

    const suggestUsers = async (filter) => {
        const response = await fetch(ApiEndpoints.SuggestUsersForDiscounts + `?filter=${filter}`);
        const data = await response.json();
        return data.map((user) => ({ value: user.id, label: getUserLabel(user) }));
    }
    const getUserLabel = (user) => `${user.firstName} ${user.lastName} (${user.email})`;
    return (
        <div class="row mb-4">
              <div class="col-md-4 mb-2 mb-md-0">
                  <AsyncSelect
                      isClearable={true}
                      onChange={(value) => onFilterChange(value, 'userId')}
                      loadOptions={suggestUsers}
                      placeholder="Filter by user"
                 />
              </div>
              <div class="col-sm-6 col-md-4 mb-2 mb-sm-0">
                   <Select
                       onChange={(value) => onFilterChange(value, 'status')}
                       placeholder="Filter by status"
                       isClearable={true}
                       options={statuses}
                   />
              </div>
              <div class="col-sm-6 col-md-4">
                  <Select
                      onChange={(value) => onFilterChange(value, 'countryId')}
                      placeholder="Filter by country"
                      isClearable={true}
                      options={countries.map(x => ({ value: x.id, label: x.name }))}
                  />
              </div>
        </div>
    )    
}
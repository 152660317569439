import React, { Component, Fragment, useRef } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import './PrivacyPolicyModal.css';

export function PrivacyPolicyModal({ setShowModal }) {
    const modalWindowRef = useRef();

    return (
        <div className="privacy-policy-modal">
            <ModalWindow
                ref={modalWindowRef}
                title="Privacy Policy"
                onClose={() => { setShowModal(false); }}
            >
                <p>This Privacy Policy (hereinafter referred to as the “Policy”) defines the procedure for collecting, using, storing, and protecting the personal data of users on the website of <strong>annashepeta.art</strong> (hereinafter referred to as the “Site”). We highly value your privacy and comply with all relevant legal requirements, including the General Data Protection Regulation (GDPR).</p>
                <h4>1. General Provisions</h4>
                <ul>
                    <li>1.1. The data controller is Anna Shepeta, contact email - <a href="mailto:contact@annashepeta.art">contact@annashepeta.art</a>.</li>
                    <li>1.2. This Policy applies to all personal data we receive from you while using our website.</li>
                </ul>
                <h4>2. Personal Data We Collect</h4>
                <div className="mb-1">2.1. We may collect the following data:</div>
                <ul>
                    <li>Name and surname.</li>
                    <li>Email address.</li>
                    <li>Shipping address.</li>
                </ul>
                <h4>3. Purpose of Data Processing</h4>
                <div className="mb-1">3.1. We collect and process personal data for the following purposes:</div>
                <ul>
                    <li>To fulfill your orders and provide services.</li>
                    <li>To send newsletters (with your consent).</li>
                </ul>
                <h4>4. Legal Basis for Processing</h4>
                <div className="mb-1">4.1. We process your data based on:</div>
                <ul>
                    <li>Your consent to the processing of data (e.g., when subscribing to newsletters).</li>
                    <li>The necessity of fulfilling a contract (e.g., for order delivery).</li>
                    <li>Legitimate interest, such as improving service quality.</li>
                </ul>
                <h4>5. Data Sharing with Third Parties</h4>
                <div className="mb-1">5.1. As part of order fulfillment or service provision, your data may be shared with third parties, such as:</div>
                <ul>
                    <li>Payment systems (Stripe).</li>
                    <li>Delivery services (Poczta Polska).</li>
                </ul>
                <h4>6. Data Retention Periods</h4>
                <div className="mb-1">6.1. Personal data is stored no longer than necessary to fulfill the purposes for which it was collected:</div>
                <ul>
                    <li>Order data is retained for 5 years to meet contractual obligations and tax reporting.</li>
                    <li>Data for marketing newsletters is retained until you withdraw your consent.</li>
                </ul>
                <h4>7. User Rights</h4>
                <div className="mb-1">7.1. You have the following rights regarding your personal data:</div>
                <ul className="mb-1">
                    <li>Right to access your data.</li>
                    <li>Right to correct inaccurate data.</li>
                    <li>Right to request data deletion (“right to be forgotten”).</li>
                    <li>Right to restrict data processing.</li>
                    <li>Right to data portability.</li>
                    <li>Right to object to data processing.</li>
                </ul>
                <div className="mb-1">7.2. To exercise these rights, please contact me via email <a href="mailto:contact@annashepeta.art">contact@annashepeta.art</a>.</div>
                <h4>8. Data Security</h4>
                <p>8.1. We take all necessary measures to protect personal data from unauthorized access, alteration, disclosure, or destruction.</p>
                <h4>9. Changes to the Policy</h4>
                <p>9.1. We reserve the right to make changes to this Policy. In the event of changes, the new version will be published on this page. We recommend checking this page periodically to stay informed about the current version of the Policy.</p>
                <p><strong>Last updated: 16.11.2024</strong></p>
                <h4>10. Contact Information</h4>
                <p>For all questions related to the processing of personal data, you can contact me via email at <a href="mailto:contact@annashepeta.art">contact@annashepeta.art</a>.</p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary w-100" onClick={() => modalWindowRef.current.closeWindow()}>Close window</button>
                </div>
            </ModalWindow>
        </div>
    );
}
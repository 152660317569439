import React, { Fragment } from 'react';

export function CautionSvgIcon() {
    return (
        <Fragment>
             <svg xmlns="http://www.w3.org/2000/svg" height="800px" width="800px" version="1.1" id="_x32_" viewBox="0 0 512 512">
               <g>
                <path class="st0" d="M387.317,0.005H284.666h-57.332h-102.65L0,124.688v102.67v57.294v102.67l124.684,124.674h102.65h57.332   h102.651L512,387.321v-102.67v-57.294v-102.67L387.317,0.005z M255.45,411.299c-19.082,0-34.53-15.467-34.53-34.549   c0-19.053,15.447-34.52,34.53-34.52c19.082,0,34.53,15.467,34.53,34.52C289.98,395.832,274.532,411.299,255.45,411.299z    M283.414,278.692c0,15.448-12.516,27.964-27.964,27.964c-15.458,0-27.964-12.516-27.964-27.964l-6.566-135.368   c0-19.072,15.447-34.54,34.53-34.54c19.082,0,34.53,15.467,34.53,34.54L283.414,278.692z"/>
               </g>
               </svg>
        </Fragment>
    );
}
import React, { Component, Fragment } from 'react';

import ErrorImg from '../../../assets/errors/cloud.png';

export function AccessDenied() {
    return (
         <Fragment>
                <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                    <img src={ErrorImg} alt="Error" style={{ maxWidth: 300 }} />
                    <p>403: You do not have access to this resource.</p>
                    <a className="text-primary" href="/">Main Page</a>
                </div>
         </Fragment>
     );
}

import * as Yup from 'yup';

export class ShopDeliveryCreateEditValidation {
    constructor() {
        this.validationSchema = Yup.object().shape({
            name: Yup.string()
                .required('Please fill the field: Name')
                .max(100, 'Name cannot be more than 100 characters'),

            internalName: Yup.string()
                .max(100, 'Internal name cannot be more than 100 characters'),

            price: Yup.number()
                .typeError('Please fill the field: Price')
                .required('Please fill the field: Price')
                .min(0, 'Price cannot be less than 0'),
        });
    }

    async validate(discount) {
        try {
            await this.validationSchema.validate(discount, { abortEarly: false });
            return null;
        } catch (errors) {
            return errors.inner[0].message;
        }
    }
}

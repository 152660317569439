import React, { Fragment, useState, useEffect } from 'react';
import './shopItemCategoriesList.css';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ShopItemCategoryCreateEditModal } from './ShopItemCategoryCreateEditModal';
import { ShopItemCategoryDeleteModal } from './ShopItemCategoryDeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export function ShopItemCategoriesList() {
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({ name: '', parentId: null });
    const [isEditing, setIsEditing] = useState(false);
    const [deleteCategoryId, setDeleteCategoryId] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const response = await fetch(ApiEndpoints.ManageShopItemCategories);
        const data = await response.json();
        setCategories(data);
    };

    const handleCreate = () => {
        setCurrentCategory({ name: '', parentId: null });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleEdit = (category) => {
        setCurrentCategory(category);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        setDeleteCategoryId(id);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedCategories = Array.from(categories);
        const [removed] = reorderedCategories.splice(result.source.index, 1);
        reorderedCategories.splice(result.destination.index, 0, removed);
        const orderUpdates = reorderedCategories.map((cat, index) => ({ id: cat.id, newOrder: index }));
        await fetch(ApiEndpoints.ReorderShopItemCategories, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(orderUpdates),
        });

        fetchCategories();
    };

    return (
        <Fragment>
            <NavMenuShop />
            <div className="container category-manager">
                <button className="btn btn-primary mb-3" onClick={handleCreate}>Create Category</button>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="categories">
                        {(provided) => (
                            <table className="table table-striped table-bordered table-hover" ref={provided.innerRef} {...provided.droppableProps}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Parent</th>
                                        <th style={{ minWidth: 180 }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((category, index) => (
                                        <Draggable key={category.id} draggableId={category.id.toString()} index={index}>
                                            {(provided) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <td data-label="Name">{category.name}</td>
                                                    <td data-label="Parent">{category.parentId ? categories.find(cat => cat.id === category.parentId)?.name : 'None'}</td>
                                                    <td data-label="Actions">
                                                        <button className="btn btn-warning mr-2" onClick={() => handleEdit(category)}>Edit</button>
                                                        <button className="btn btn-danger" onClick={() => handleDelete(category.id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </tbody>
                            </table>
                        )}
                    </Droppable>
                </DragDropContext>
                {showModal && (
                    <ShopItemCategoryCreateEditModal
                        currentCategory={currentCategory}
                        setCurrentCategory={setCurrentCategory}
                        setShowModal={setShowModal}
                        categories={categories}
                        fetchCategories={fetchCategories}
                        isEditing={isEditing}
                    />
                )}
                {deleteCategoryId && (
                    <ShopItemCategoryDeleteModal
                        deleteCategoryId={deleteCategoryId}
                        setDeleteCategoryId={setDeleteCategoryId}
                        fetchCategories={fetchCategories}
                    />
                )}
            </div>
        </Fragment>
    );
}

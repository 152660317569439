import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './mediaGallery.css'
import { Gallery } from "react-grid-gallery";
import 'react-image-lightbox/style.css'; 
import { NavMenu } from '../navMenu/NavMenu';
import { ApiEndpoints } from '../../api/apiEndpoints';
import { MediaGalleryLightBox } from './MediaGalleryLightBox';
import { ClickOnRenderedImage } from './ClickOnRenderedImage';

export function MediaGallery () {
  const displayName = MediaGallery.name;

  const query = new URLSearchParams(useLocation().search);  
  const [tabIndex, setTabIndex] = useState(query.get("tab") == 1 ? 1 : 0);
  
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [artImages, setArtImages] = useState([]);
  const [isClearing, setIsClearing] = useState(false);
  
    useEffect(() => {
      document.title = 'Illustration | Anna Shepeta';
      const fetchImages = async () => {
        try {
          const response = await fetch(ApiEndpoints.Illustration);
          const data = await response.json();
          
          const formatImage = (item: any) => ({
            src: ApiEndpoints.PublicFile + "/" + item.thumbnailFileName,
            original: ApiEndpoints.PublicFile + "/" + item.originalFileName,
            height: item.thumbnailHeight,
            width: item.thumbnailWidth,
            caption: item.description,
          });

          setPortfolioImages(data.portfolioImages.map(formatImage));
          setArtImages(data.artImages.map(formatImage));

        } catch (error) {
          console.error('Error fetching images', error);
        }
      };
  
      fetchImages();
    }, []);
  
  const [portfolioIndex, setPortfolioIndex] = useState(-1);
  const [artIndex, setArtIndex] = useState(-1);

  const handleClick = (index, item) => {
      tabIndex === 0 ? setPortfolioIndex(index) : setArtIndex(index);
  }

  const handleTabClick = (index) => {
      if (tabIndex !== index) {
        setIsClearing(true);
        setTimeout(() => {
          setTabIndex(index);
          setIsClearing(false); 
        }, 50);
      }
    };

  return (
    <div className="gallery-content">
    <meta
        name="description"
        content="Discover Anna Shepeta’s captivating illustrations, created for books, projects, and more. Explore her unique style and creative journey!"
    />
      <NavMenu />
         <div className="gallery-tabs">
            <a className={tabIndex === 0 ? 'active' : ''} onClick={() => handleTabClick(0)}>
                <span className={'gallery-tab-title portfolio ' + (tabIndex === 0 ? 'text-decoration-underline' : '')}>Portfolio</span>
            </a>
            <a className={tabIndex === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>
                <span className={'gallery-tab-title art ' + (tabIndex === 1 ? 'text-decoration-underline' : '')}>Art</span>
            </a>
        </div>
      { query.get("selected") && <ClickOnRenderedImage targetSrc={query.get("selected")} /> }
      <Gallery
        images={isClearing ? [] : (tabIndex === 0 ? portfolioImages : artImages)}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={250}
        margin={7}
        tileViewportStyle={{ height: 250 }}
      />
      <MediaGalleryLightBox
          images={tabIndex === 0 ? portfolioImages : artImages}
          index={tabIndex === 0 ? portfolioIndex : artIndex}
          setIndex={tabIndex === 0 ? setPortfolioIndex : setArtIndex}
      />
    </div>
  );
}

import React, { Component, Fragment } from 'react';

import ErrorImg from '../../../assets/errors/cloud.png';

export function NotFound() {
    return (
         <Fragment>
                <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                    <img src={ErrorImg} alt="Error" style={{ maxWidth: 300 }} />
                    <p>404: Resource is not found.</p>
                    <a href="/" className="text-primary">Main Page</a>
                </div>
         </Fragment>
     );
}

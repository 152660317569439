import React, { Component, Fragment, useRef } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import './InconsistentStatuesModal.css';

export function InconsistentStatuesModal({ setShowModal }) {
    const modalWindowRef = useRef();

    return (
        <div className="inconsistent-status">
            <ModalWindow
                ref={modalWindowRef}
                title="Oops! There’s an Issue with Your Order Status"
                onClose={() => { setShowModal(false); }}
            >
                <p>
                    It looks like your payment session has expired, but the status still shows as "New" (what means waiting for payment).
                    We’ve already notified administrator about this, and it should be resolved shortly.
                </p>
                <p>
                    Please check back shortly, and if the issue isn't resolved soon, feel free to reach out to contact directly.
                    Thank you for your patience!
                </p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Okay</button>
                </div>
            </ModalWindow>
        </div>
    );
}
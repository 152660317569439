import React, { Fragment } from 'react';
import './AppliedDeniedIcon.css';

export function AppliedDeniedIcon({ isApplied, isPending }) {
    const size = 30;
    return (
        <Fragment>
            {isApplied ? (
                <div className="animation-ctn">
                    <div className="icon icon--order icon--order-success svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`}>
                            <g fill="none" stroke="#22AE73" strokeWidth="2">
                                <circle cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <circle id="colored" fill="#22AE73" cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <polyline className="st0 animation-ctn-polyline" stroke="#fff" strokeWidth="10" points={`${size * 0.28},${size / 2} ${size * 0.43},${size * 0.64} ${size * 0.73},${size * 0.32}`} />
                            </g>
                        </svg>
                    </div>
                </div>
            ) : isPending ? (
                <div className="animation-ctn">
                    <div className="icon icon--order icon--order-pending svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`}>
                            <g fill="none" stroke="#B39DDB" strokeWidth="2">
                                <circle cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <circle id="colored" fill="#B39DDB" cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <path className="st0 animation-ctn-polyline" transform="translate(8,6) scale(0.7)" fill="white" d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                            </g>
                        </svg>
                    </div>
                </div>
            ) : (
                <div className="animation-ctn">
                    <div className="icon icon--order icon--order-failed svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`}>
                            <g fill="none" stroke="#F44812" strokeWidth="2">
                                <circle cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <circle id="colored" fill="#F44812" cx={size / 2} cy={size / 2} r={size / 2 - 2} className="animation-ctn-circle"></circle>
                                <polyline className="st0 animation-ctn-polyline" stroke="#fff" strokeWidth="10" points={`${size * 0.28},${size / 2} ${size * 0.73},${size / 2}`} />
                            </g>
                        </svg>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie' 

export const TranslateWidget = () => {

    const [wasInitialized, setWasInitialized] = useState(false);
       /* const cookies = new Cookies();
 
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length > 2) {
        let languageCode = parts[0];
        if (languageCode.toLowerCase() === 'zh-cn') {
            languageCode = 'zh-CN';
        } else if (languageCode.toLowerCase() === 'zh-tw') {
            languageCode = 'zh-TW';
        }

        document.cookie = 'googtrans=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = `googtrans=/en/${languageCode};domain=.annashepeta.art;path=/`;
    }

  cookies.addChangeListener(({ name, value }) => {
      if (name === "googtrans") {
         if (!value) {
             removeSubdomain();
             return;
           }

           const match = value.match(/^\/[a-z]{2}\/([a-z]{2})$/i);
           if (match) {
             const languageCode = match[1];
             setSubdomain(languageCode);
           } else {
             removeSubdomain();
           }
      }
  });
  
  const setSubdomain = (language) => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
  
    if (parts.length > 2) {
      parts[0] = language;
    } else {
      parts.unshift(language);
    }
  
    const newHostname = parts.join('.');
    const newUrl = `${window.location.protocol}//${newHostname}${window.location.pathname}`;

    tryNavigate(newUrl);
  };

  const removeSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length > 2) {
      parts.shift();
      const newHostname = parts.join('.');
      const newUrl = `${window.location.protocol}//${newHostname}${window.location.pathname}`;
      tryNavigate(newUrl);
    }
  };

  const tryNavigate = (newUrl) => {
    try {
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
    catch (e) {
    }
  }
*/

  useEffect(() => {
    //if (wasInitialized) {
    //  return;
    //}
    window.gtranslateSettings = {
      default_language: "en",
      native_language_names: true,
      wrapper_selector: ".gtranslate_wrapper",
      switcher_horizontal_position: "right",
      switcher_vertical_position: "top",
      float_switcher_open_direction: "bottom"
    };

    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js';
    script.defer = true;

    document.body.appendChild(script);
    setWasInitialized(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="gtranslate_wrapper"></div>;
};
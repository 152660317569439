import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import { ModalValidation } from '../../../modal/validation/ModalValidation';
import { ShopItemCategoryCreateEditValidation } from './ShopItemCategoryCreateEditValidation';

export function ShopItemCategoryCreateEditModal({currentCategory, setCurrentCategory, setShowModal, categories, fetchCategories, isEditing}) {
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const validator = new ShopItemCategoryCreateEditValidation();
    const modalWindowRef = useRef();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) return;
        setSubmitting(true);

        const validationError = await validator.validate(currentCategory);
        if (validationError) {
            setError(validationError);
            document.getElementsByClassName("modal")[0].scrollTo(0, 0);
            setSubmitting(false);
            return;
        }

        const response = await fetch(ApiEndpoints.ManageShopItemCategories, {
            method: isEditing ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(currentCategory),
        });
    
        fetchCategories();
        modalWindowRef.current.closeWindow();
        };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentCategory(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

   return (
      <ModalWindow
            ref={modalWindowRef}
            title={isEditing ? 'Edit Category' : 'Create Category'}
            onClose={() => setShowModal(false)}
      >
           <form onSubmit={handleSubmit}>
             <ModalValidation error={error} />
             <div className="form-group">
               <label htmlFor="categoryName">Name</label>
               <input
                 type="text"
                 className="form-control"
                 id="categoryName"
                 name="name"
                 value={currentCategory.name}
                 onChange={handleInputChange}
               />
             </div>
             <div className="form-group">
               <label htmlFor="categoryParent">Parent Category</label>
               <select
                 className="form-control"
                 id="categoryParent"
                 name="parentId"
                 value={currentCategory.parentId || ''}
                 onChange={handleInputChange}>
                 <option value="">None</option>
                 {categories
                   .filter(cat => cat.id !== currentCategory.id)
                   .map(cat => (
                     <option key={cat.id} value={cat.id}>{cat.name}</option>
                   ))}
               </select>
             </div>
             <button type="submit" className="btn btn-primary" disabled={submitting}>
               {isEditing ? 'Save Changes' : 'Create Category'}
             </button>
           </form>
      </ModalWindow>
    );
 }
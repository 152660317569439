export class CartStorage {
    constructor() {
        this.storageKey = 'cart';
        this.cart = this._getCartFromStorage();
    }

    _getCartFromStorage() {
        const cart = localStorage.getItem(this.storageKey);
        return cart ? JSON.parse(cart) : {};
    }

    _saveCartToStorage() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.cart));
    }

    addItem(itemId, count = 1) {
        if (this.cart[itemId]) {
            this.cart[itemId] += count;
        } else {
            this.cart[itemId] = count;
        }
        this._saveCartToStorage();
    }

    updateItem(itemId, count) {
        if (this.cart[itemId]) {
            if (count > 0) {
                this.cart[itemId] = count;
            } else {
                delete this.cart[itemId];
            }
            this._saveCartToStorage();
        }
    }

    removeItem(itemId) {
        if (this.cart[itemId]) {
            delete this.cart[itemId];
            this._saveCartToStorage();
        }
    }

    getItems() {
        return this.cart;
    }

    clearCart() {
        this.cart = {};
        this._saveCartToStorage();
    }

    getItemCount() {
        return Object.keys(this.cart).length;
    }

    getTotalCount() {
        return Object.values(this.cart).reduce((acc, count) => acc + count, 0);
    }
}

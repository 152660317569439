export function getStatuses() {
    return [
        { value: 0, label: 'New' },
        { value: 1, label: 'Payment received' },
        { value: 2, label: 'Shipped' },
        { value: 3, label: 'Delivered' },
        { value: 4, label: 'Cancelled' },
        { value: 5, label: 'Awaiting return' },
        { value: 6, label: 'Refunded' },
        { value: 7, label: 'Disputed' },
        { value: 8, label: 'Archived' }
    ];
}
import React, { useState, useEffect } from 'react';
import { NavMenuShop } from '../../../../navMenu/NavMenuShop';
import { formatDateToDisplay } from '../../../../../functions/dates';
import { getFixedNumberPrice } from '../../../../../functions/prices';
import { OrderStatusBadge } from '../../../orderDetails/orderStatusBadge/OrderStatusBadge';
import { ApiEndpoints } from '../../../../../api/apiEndpoints';
import './ShopOrderManage.css';
import { getStatuses } from '../shopOrdersFunctions';
import Select from 'react-select';

export function ShopOrderManage({ orderId }) {
    const [shopOrder, setShopOrder] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [statusToSet, setStatusToSet] = useState(null);
    const [infoFromSeller, setInfoFromSeller] = useState('');
    const statuses = getStatuses();
    document.title = 'Manage order | Anna Shepeta';

     useEffect(() => {
        fetch(`${ApiEndpoints.ManageShopOrders}/${orderId}`)
            .then(response => response.json())
            .then(data => {
                setShopOrder(data);
                setInfoFromSeller(data.infoFromSeller);
            });
    }, [orderId]);

    const cancelOrder = async () => {
         setSubmitting(true);
          await fetch(ApiEndpoints.CancelManagePayment, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orderId)
          });

          window.location.reload();
    }

    const changeStatus = async () => {
        setSubmitting(true);
        if (!statusToSet) {
            return;
        }

        await fetch(ApiEndpoints.ChangeOrderStatus, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: orderId, status: statusToSet.value })
          });

          window.location.reload();
    }

    const changeInfoFromSeller = async () => {
        setSubmitting(true);
        await fetch(ApiEndpoints.ChangeOrderInfoFromSeller, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: orderId, infoFromSeller: infoFromSeller })
          });

          window.location.reload();
    }

    if (!shopOrder) {
        return <div></div>;
    }

    return (
         <div className="order-manager">
            <NavMenuShop />
            <div className="container">
                <h1 align="center">Manage order</h1>
                 <div className="info-card">
                    <h2>General info</h2>
                    <hr/>
                    <p><strong>Buyer first name: </strong>{shopOrder.firstName}</p>
                    <p><strong>Buyer last name: </strong>{shopOrder.lastName}</p>
                    <p><strong>Buyer email: </strong>{shopOrder.email}</p>
                    <p><strong>Date & Time: </strong>{formatDateToDisplay(new Date(shopOrder.creation))}</p>
                    <p><strong>Total cost: </strong><span className="notranslate">{getFixedNumberPrice(shopOrder.totalPrice)} €</span></p>
                    <p><strong>Status: </strong> <OrderStatusBadge status={shopOrder.status} /></p>
                    <p><strong>Inconsistency state: </strong>
                        {
                            (shopOrder.status === 'New' && !shopOrder.paymentLink)
                                ? <span className="text-danger">Inconsistent</span>
                                : <span className="text-success">Consistent</span>
                        }
                    </p>
                    <p>
                        <strong>Info from seller: </strong>
                        <textarea
                            className="form-control"
                            value={infoFromSeller}
                            onChange={(e) => setInfoFromSeller(e.target.value)}
                        />
                        <button
                            className="btn btn-secondary w-100 mt-2"
                            onClick={() => changeInfoFromSeller()}
                            disabled={submitting}>
                                Update info from seller
                        </button>
                    </p>
                    <hr />
                    <div className="row">
                        <div className="col-md-3 m-auto">
                            <strong>Actions: </strong>
                        </div>
                        <div className="col-md-9">
                            {
                                shopOrder.status === 'New' &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <button
                                                className="btn btn-danger w-100"
                                                onClick={() => cancelOrder(shopOrder.id)}
                                                disabled={submitting}>
                                                    Cancel and revert items
                                            </button>
                                        </div>
                                    </div>
                            }
                            <div className="row">
                                <div className="col-md-6 mb-1">
                                    <Select
                                       placeholder="Select status"
                                       isClearable={true}
                                       options={statuses}
                                       onChange={(selectedOption) => setStatusToSet(selectedOption)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <button
                                        className="btn btn-primary w-100"
                                        onClick={() => changeStatus()}
                                        disabled={submitting}>
                                            Transfer to status
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="info-card mt-4">
                      <h2>Delivery info</h2>
                      <hr/>
                      <p><strong>Country: </strong>{shopOrder.country}</p>
                      <p><strong>Province: </strong>{shopOrder.province}</p>
                      <p><strong>City: </strong>{shopOrder.city}</p>
                      <p><strong>Postal / Zip code: </strong>{shopOrder.postalCode}</p>
                      <p><strong>Address line 1: </strong>{shopOrder.address1}</p>
                      <p><strong>Address line 2: </strong>{shopOrder.address2}</p>
                      <p><strong>Comments: </strong>{shopOrder.comments}</p>
                      <h4>Delivery methods</h4>
                      <hr/>
                      {
                          shopOrder.deliveries.map(delivery =>
                            <div className="card m-3 delivery-card">
                               <div className="card-header delivery-card-header d-flex flex-wrap justify-content-between">
                                   <span>
                                        {delivery.name}
                                    </span>
                                     <span>
                                        Cost: <span className="notranslate">{getFixedNumberPrice(delivery.price)} €</span>
                                     </span> 
                               </div>
                               <div className="card-body">
                                  {delivery.description}
                               </div>
                            </div>
                         )
                      }
                  </div>
                  <div className="info-card mt-4 mb-4">
                      <h2>Items</h2>
                        <table>
                          <thead>
                            <tr>
                              <th scope="col" width="100">Preview</th>
                              <th scope="col">Name</th>
                              <th scope="col">Price</th>
                              <th scope="col">Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                              {
                                  shopOrder.items.map(item =>
                                        <tr>
                                            <td data-label="Preview">
                                                <a className="text-decoration-none" href={`/shop/${item.itemId}`}>
                                                    <img className="order-item-preview" src={ApiEndpoints.PublicFile + '/' + item.thumbnailFileName} />
                                                </a>
                                            </td>
                                            <td data-label="Name">
                                                <a className="text-decoration-none text-dark" href={`/shop/${item.itemId}`}>{item.name}</a>
                                            </td>
                                            <td className="notranslate" data-label="Price">{getFixedNumberPrice(item.price)} €</td>
                                            <td data-label="Quantity">{item.quantity}</td>
                                        </tr>)
                              }
                          </tbody>
                        </table>
                  </div>

            </div>
        </div>
    );
}
import React, { Component, Fragment, useState, useRef, useEffect } from 'react';
import { ModalWindow } from '../../../modal/ModalWindow';
import './ShopCartExceedsStockQuantity.css';

export function ShopCartExceedsStockQuantity({ setShopExceedsQuantity }) {
    const modalWindowRef = useRef();

    return (
        <div className="shop-cart-exceeds-quantity">
            <ModalWindow
                ref={modalWindowRef}
                title="Limit Reached"
                onClose={() => { setShopExceedsQuantity(false); }}
            >
                <p>You are attempting to add more items than are available in stock</p>
                <p>The maximum quantity of this item is already in your cart</p>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Continue shopping</button>
                  <a href="/cart" type="button" className="btn btn-primary">Go to cart</a>
                </div>
            </ModalWindow>
        </div>
    );
}
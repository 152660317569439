import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';
import { useLocation } from 'react-router-dom';
import { ApiEndpoints } from '../../api/apiEndpoints';

export function NavMenu(props) {
  const displayName = NavMenu.name;
  
  const handleMouseOver = (e, hoverImage) => {
      e.currentTarget.src = hoverImage;
    }
  
  const handleMouseOut = (e, normalImage) => {
      e.currentTarget.src = normalImage;
    }

    const [state, setState] = useState({ collapsed: true });
    const toggleNavbar = () => {
       setState({ collapsed: !state.collapsed });
    }

  const getPublicFileUrl = (fileName) => ApiEndpoints.PublicFile + "/zz_nav_" + fileName;

  var location = useLocation();
  return (
      <header>
        <Navbar className="navbar-expand-md navbar-toggleable-md ng-white box-shadow mb-3" light>
          <Container>
            <NavbarBrand href="/">
                <img src={getPublicFileUrl('logo.png')} className="logo" alt="Logo" />
            </NavbarBrand>
            <NavbarToggler style={{backgroundImage:`url(${getPublicFileUrl('nav-toggle.png')})`}} onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!state.collapsed} navbar>
              <ul className="navbar-nav flex-wrap">
                <NavItem>
                  <NavLink className="text-dark" href="/shop">
                    <span className={'link-title shop-nav-title ' + (location.pathname === '/shop' ? 'text-decoration-underline' : '')}>Shop</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/illustration">
                    <span className={'link-title illustration-nav-title ' + (location.pathname === '/illustration' ? 'text-decoration-underline' : '')}>Illustration</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/about">
                      <span className={'link-title about-nav-title ' + (location.pathname === '/about' ? 'text-decoration-underline' : '')}>About</span>
                  </NavLink>
                </NavItem>
                <NavItem className="d-flex align-items-center">
                  <a className="text-dark nav-link" href="mailto:contact@annashepeta.art">
                      <img src={getPublicFileUrl('contact.png')} alt="Contact" className="img-fluid" onMouseOver={(e) => handleMouseOver(e, getPublicFileUrl('contact_selected.png'))} onMouseOut={(e) => handleMouseOut(e, getPublicFileUrl('contact.png'))} />
                  </a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
}

import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';
import DatePicker from "react-datepicker";

export function UserSetLockoutModal({userLockoutEnd, userId, setUserId, fetchUsers, setPage}) {
    const [submitting, setSubmitting] = useState(false);
    const [lockoutEnd, setLockoutEnd] = useState(userLockoutEnd);
    const modalWindowRef = useRef();

    const confirmSubmit = async (e) => {
        if (submitting) return;
        setSubmitting(true);

        await fetch(`${ApiEndpoints.SetLockout}/${userId}?lockoutEnd=${lockoutEnd !== null ? new Date(lockoutEnd).toISOString() : null}`, { method: "GET" });
        setPage(0);
        await fetchUsers(0);
        modalWindowRef.current.closeWindow();
    }

    return (
        <ModalWindow
            ref={modalWindowRef}
            title="Set lockout for the user"
            onClose={() => setUserId(null)}
        >
            <p>Please specify new lockout end time for the user.</p>
            <DatePicker
                id="lockoutEnd"
                selected={lockoutEnd && new Date(lockoutEnd)}
                name="lockoutEnd"
                className="form-control"
                dateFormat="dd.MM.yyyy HH:mm:ss"
                showTimeSelect
                onChange={(date) => setLockoutEnd(date)}
            />
            <button type="button" className="btn btn-secondary" onClick={() => setLockoutEnd(null)}>Clear</button>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={confirmSubmit} disabled={submitting}>Confirm</button>
            </div>
        </ModalWindow>
    );
}
import React, { Fragment, useState, useEffect } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import { UserResetAccessFailedCountModal } from './UserResetAccessFailedCountModal';
import { UserSetLockoutModal } from './UserSetLockoutModal';
import { UserChangeEmailModal } from './UserChangeEmailModal';
import { UserImpersonateModal } from './UserImpersonateModal';
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerImg from '../../../../assets/spinner.gif';
import './UsersList.css';

export function UsersList() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [resetAccessFailedCountUserId, setResetAccessFailedCountUserId] = useState(null);
    const [setLockoutUserId, setSetLockoutUserId] = useState(null);
    const [setNewEmailUserId, setSetNewEmailUserId] = useState(null);
    const [impersonateUserId, setImpersonateUserId] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async (currPage, isAppend) => {
        var queryParams = [
             (currPage != null ? `page=${currPage}` : `page=${page}`)
        ];

        const response = await fetch(ApiEndpoints.ManageUsers + '?' + queryParams.filter(x => x).join('&'));
        const data = await response.json();
        setUsers(isAppend ? users.concat(data.items) : data.items);
        setCount(data.count);
    };

    return (
        <Fragment>
        <NavMenuShop />
        <div className="container users-manager">
            <InfiniteScroll
                dataLength={users.length}
                next={() => {
                  const currPage = +page + 1;
                  setPage(currPage);
                  fetchUsers(currPage, true);
                }}
                hasMore={users.length < count}
                loader={
                    <div className="d-flex justify-content-center align-items-center pretty-font">
                      <img className="loader-img" src={SpinnerImg} />
                      <span>Loading...</span>
                    </div>}
                    >
                    <table className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th width="10%">First name</th>
                            <th width="10%">Last name</th>
                            <th width="20%">Email</th>
                            <th width="80">Email verified</th>
                            <th width="120">Newsletters</th>
                            <th width="10%">Access failed count</th>
                            <th width="10%">Lockout end</th>
                            <th style={{ minWidth: 180 }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                         <tr>
                            <td data-label="First name">{user.firstName}</td>
                            <td data-label="Last name">{user.lastName}</td>
                            <td data-label="Email">{user.email}</td>
                            <td data-label="Email verified">{user.emailConfirmed ? "✅" : "❌"}</td>
                            <td data-label="Newsletters">{!user.unsubscribedFromNewsletters ? "✅" : "❌"}</td>
                            <td data-label="Access failed count">{user.accessFailedCount}</td>
                            <td data-label="Lockout end">{user.lockoutEnd && new Date(user.lockoutEnd).toLocaleString()}</td>
                            <td>
                                <button className="btn actions-btn btn-primary mr-2" onClick={() => setImpersonateUserId(user.id)}>Impersonate</button>
                                <button className="btn actions-btn btn-warning mr-2" onClick={() => setSetNewEmailUserId(user.id)}>Edit email address</button>
                                <button className="btn actions-btn btn-warning mr-2" onClick={() => setResetAccessFailedCountUserId(user.id)}>Reset Access Failed Count</button>
                                <button className="btn actions-btn btn-warning mr-2" onClick={() => setSetLockoutUserId(user.id)}>Set lockout (Deactivate)</button>
                                <button className="btn actions-btn btn-danger" onClick={() => {}}>Delete all personal data</button>
                            </td>
                        </tr>
                    ))}
                     </tbody>
                 </table>
                </InfiniteScroll>
                {resetAccessFailedCountUserId && (
                      <UserResetAccessFailedCountModal
                          userId={resetAccessFailedCountUserId}
                          setUserId={setResetAccessFailedCountUserId}
                          fetchUsers={fetchUsers}
                          setPage={setPage}
                      />
                )}
                {setLockoutUserId && (
                      <UserSetLockoutModal
                          userLockoutEnd={users.find(x => x.id === setLockoutUserId)?.lockoutEnd}
                          userId={setLockoutUserId}
                          setUserId={setSetLockoutUserId}
                          fetchUsers={fetchUsers}
                          setPage={setPage}
                      />
                )}
                {setNewEmailUserId && (
                      <UserChangeEmailModal
                          userEmail={users.find(x => x.id === setNewEmailUserId)?.email}
                          userId={setNewEmailUserId}
                          setUserId={setSetNewEmailUserId}
                          fetchUsers={fetchUsers}
                          setPage={setPage}
                      />
                )}
                {impersonateUserId && (
                      <UserImpersonateModal
                          userId={impersonateUserId}
                          setUserId={setImpersonateUserId}
                          fetchUsers={fetchUsers}
                          setPage={setPage}
                      />
                )}
        </div>
    </Fragment>
    );
}
import React, { Component, Fragment, useState, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { ModalWindow } from '../../../modal/ModalWindow';

export function ShopItemDeleteModal({deleteItemId, setDeleteItemId, fetchItems, setPage}) {
    const [submitting, setSubmitting] = useState(false);
    const modalWindowRef = useRef();

    const confirmDelete = async (e) => {
        if (submitting) return;
        setSubmitting(true);

        await fetch(`${ApiEndpoints.ManageShopItems}/${deleteItemId}`, { method: "DELETE" });
        setPage(0);
        await fetchItems(0);
        modalWindowRef.current.closeWindow();
    }

    return (
        <ModalWindow
            ref={modalWindowRef}
            title="Confirm Delete"
            onClose={() => setDeleteItemId(null)}
        >
            <p>Are you sure you want to delete this shop item?</p>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => modalWindowRef.current.closeWindow()}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={confirmDelete} disabled={submitting}>Cancel and revert items</button>
            </div>
        </ModalWindow>
    );
}
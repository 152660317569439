export class ApiEndpoints {
    static Illustration = '/api/illustration';
    static PublicFile = '/api/publicFile';
    static Users = '/api/user';

    /* Shop */
    static ShopFilters = '/api/shopFilters';
    static ShopItems = '/api/shopItems';
    static ShopCartItems = '/api/shopItems/cart';
    static ShippingCountries = '/api/shipping/countries';
    static ShippingMethods = '/api/shipping/methods';
    static CheckPromoCode = '/api/shopDiscounts/checkPromoCode';
    static ShopOrders = '/api/shopOrders';
    static HasUnfinishedOrders = '/api/shopOrders/hasUnfinished';
    static CancelPayment = '/api/shopOrders/cancel';

    /* Shop management */
    static ManageShopItemCategories = '/api/management/shopItemCategories';
    static ReorderShopItemCategories = '/api/management/shopItemCategories/reorder';
    static ManageShopItems = '/api/management/shopItems';
    static ReorderShopItems = '/api/management/shopItems/reorder';
    static ManageShopDiscounts = '/api/management/shopDiscounts';
    static ManageShopDeliveries = '/api/management/shopDeliveries';
    static SuggestUsersForDiscounts = '/api/management/shopDiscounts/suggestUsers';
    static SuggestShopItemsForDiscounts = '/api/management/shopDiscounts/suggestShopItems';
    static SuggestShopItemCategoriesForDiscounts = '/api/management/shopDiscounts/suggestShopItemCategories';
    static SuggestCountriesForDeliveries = '/api/management/shopDeliveries/suggestCountries';
    static ManageShopOrders = '/api/management/shopOrders';
    static SuggestUsersForOrders = '/api/management/shopOrders/suggestUsers';
    static CancelManagePayment = '/api/management/shopOrders/cancel';
    static ChangeOrderStatus = '/api/management/shopOrders/changeStatus';
    static ChangeOrderInfoFromSeller = '/api/management/shopOrders/changeInfoFromSeller';

    /* User management */
    static ManageUsers = '/api/management/users';
    static ResetAccessFailedCount = '/api/management/users/resetAccessFailedCount';
    static SetLockout = '/api/management/users/setLockout';
    static ChangeEmail = '/api/management/users/changeEmail';
    static Impersonate = '/api/management/users/impersonate';
}
import React, { useState, useEffect, useRef } from 'react';
import { ApiEndpoints } from '../../../../api/apiEndpoints';
import { getFixedNumberPrice } from '../../../../functions/prices';
import { NavMenuShop } from '../../../navMenu/NavMenuShop';
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerImg from '../../../../assets/spinner.gif';
import { formatDateToDisplay } from '../../../../functions/dates';
import { OrderStatusBadge } from '../../orderDetails/orderStatusBadge/OrderStatusBadge';
import { ShopOrderFilters } from './ShopOrderFilters';
import './ShopOrdersList.css';

export function ShopOrdersList() {
    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(-1);
    const [ordersPage, setOrdersPage] = useState(0);
    const [filters, setFilters] = useState({});

    const [showExpandButtons, setShowExpandButtons] = useState({});
    const [expandedItems, setExpandedItems] = useState({});
    const orderRefs = useRef({});
    const scrollPosition = useRef(0);

    useEffect(() => {
        setTimeout(() => {
             orders?.forEach((item, index) => {
                const textElement = orderRefs.current[index];
                if (textElement && textElement.scrollHeight > 170 ) {
                    setShowExpandButtons(prevState => ({
                        ...prevState,
                        [index]: true,
                    }));
                }
            });
        }, 0);
    }, [orders]);
    
    useEffect(async () => {
        await fetchOrders(0, false);
    }, []);

    const fetchOrders = async (page, isAppend, currFilters) => {
        scrollPosition.current = window.pageYOffset;
        var queryParams = [ `page=${page}` ];
        var queryFilters = currFilters || filters;
        Object.keys(queryFilters).forEach(key => {
            if (queryFilters[key] != null) {
                queryParams.push(`${key}=${queryFilters[key]}`);
            }
        });
    
        const response = await fetch(ApiEndpoints.ManageShopOrders + '?' + queryParams.filter(x => x).join('&'));
        const data = await response.json();
        
        setOrders(isAppend ? orders.concat(data.items) : data.items);
        setOrdersCount(data.count);
        window.scrollTo({ top:scrollPosition.current, left:0, behavior: "instant"})
    }

    const toggleOrder = (id) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <div className="container orders-manager">
        <NavMenuShop />
        <ShopOrderFilters filters={filters} setFilters={setFilters} setPage={setOrdersPage} fetchOrders={fetchOrders} />
        <div>
        {   ordersCount === -1 ? <p>Loading...</p> :
            ordersCount === 0
            ? <p>There are no orders</p>
            :
             <InfiniteScroll
                dataLength={orders.length}
                next={() => {
                  const newPage = +ordersPage + 1;
                  setOrdersPage(newPage);
                  fetchOrders(newPage, true);
                }}
                hasMore={orders.length < ordersCount}
                loader={
                    <div className="d-flex justify-content-center align-items-center pretty-font order-items-loader">
                      <img className="loader-img" src={SpinnerImg} />
                      <span>Loading...</span>
                    </div>}
             >
             {
                    orders.map((order, index) => {
                        const shouldShowExpandButton = showExpandButtons[index];
                        const isExpanded = expandedItems[index];
                        return (
                            <div key={index} className={`row position-relative ${ordersPage == 0 ? 'order-row-animated' : ''}`} >
                                <div className="d-flex justify-content-between w-100">
                                    <span class="badge badge-secondary order-badge">{formatDateToDisplay(new Date(order.creation))}</span>
                                    <OrderStatusBadge status={order.status} />
                                </div>
                                <div className="row m-0 flex-md-row-reverse">
                                    <div className="col-md-4 text-end p-0 m-0">
                                        <div className="order-buttons d-flex flex-column justify-content-center">
                                            <a className="btn summary-btn btn-secondary w-100 mt-2" href={`/order/manage/${order.id}`}>Details</a>
                                            <p className="w-100 mt-2 mb-0 text-center">Country: {order.country}</p>
                                            <p className="w-100 mb-0 text-center">Buyer: {order.firstName} {order.lastName}<br/>{order.email}</p>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-8 p-0 m-0 items-container"
                                        ref={el => orderRefs.current[index] = el}
                                        style={{
                                                maxHeight: isExpanded ? '2500px' : '170px',
                                                overflow: 'hidden'
                                        }}
                                    >
                                        {
                                            order.items.map((item, index) => {
                                                return (
                                                    <div key={index} className="order-item d-flex flex-row" >
                                                        <a href={`/shop/${item.itemId}`} className="text-decoration-none">
                                                            <img className="order-item-preview" src={ApiEndpoints.PublicFile + '/' + item.thumbnailFileName} />
                                                        </a>
                                                        <div className="w-100 d-flex flex-column">
                                                            <h6 className="m-0 flex-grow-1">
                                                                <a href={`/shop/${item.itemId}`} className="text-decoration-none text-dark">
                                                                 {item.name}
                                                                </a>
                                                            </h6>
                                                            <p className="m-0 order-item-info">
                                                                Price:
                                                                <span className="notranslate"> {getFixedNumberPrice(item.discountedPrice)} €</span>
                                                            </p>
                                                            <p className="m-0 order-item-info">Quantity: {item.quantity}</p>
                                                        </div>
                                                    </div>
                                                )})
                                            
                                        }
                                    </div>
                                </div>
                                {shouldShowExpandButton && (
                                  <button className="toggle-text-btn" onClick={() => toggleOrder(index)}>
                                      {isExpanded ? 'Show less' : 'Show more'}
                                  </button>
                              )}
                        </div>
                        );
                    }).reduce((prev, curr) => prev ? [prev, (<hr className="my-4" />), curr] : curr, '')
                }
            </InfiniteScroll>
        }
        </div>
        </div>
    );
}